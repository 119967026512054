import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme/theme';

const ProjectLinksEmptyBox = () => {
    const { t } = useTranslation();
    const translation = {
        empty: t('global_noData'),
    };

    return (
        <Box
            minHeight={'3rem'}
            border={'solid'}
            borderColor={theme.palette.background.default}
            borderRadius={'5px'}
        >
            <Typography
                textAlign={'center'}
                height={'100%'}
                paddingTop={'1rem'}
                color={theme.typography.background.color}
            >
                {translation.empty}
            </Typography>
        </Box>
    );
};

export default ProjectLinksEmptyBox;
