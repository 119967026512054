import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import NoData from '../../../common/NoData';

const StyledText = ({
    color,
    primaryText,
    secondaryText,
    primaryTextFontSize = '2rem',
    secondaryTextFontSize = '1.125rem',
}: {
    color: string;
    primaryText?: string | number;
    secondaryText?: string | null;
    primaryTextFontSize?: string;
    secondaryTextFontSize?: string;
}) => {
    const isEmptyOrUndefined = !primaryText && primaryText !== 0;
    return isEmptyOrUndefined ? (
        <NoData />
    ) : (
        <Box sx={{ color, textAlign: 'center' }}>
            <Typography
                style={{ fontSize: primaryTextFontSize, fontWeight: 'bold' }}
            >
                {primaryText}
            </Typography>
            {secondaryText && (
                <Typography
                    style={{
                        fontSize: secondaryTextFontSize,
                        fontWeight: 400,
                    }}
                >
                    {secondaryText}
                </Typography>
            )}
        </Box>
    );
};

export default StyledText;
