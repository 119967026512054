import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

function HelpIconTooltip(props: {
    title?: string | undefined;
    id?: string | undefined;
}) {
    if (!props.title) return <></>;

    return (
        <>
            <Tooltip title={props.title} id={props.id} data-testid={props.id}>
                <IconButton>
                    <HelpOutlineIcon />
                </IconButton>
            </Tooltip>
        </>
    );
}

export default HelpIconTooltip;
