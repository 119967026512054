import {
    DataGrid,
    GridColDef,
    GridColumnVisibilityModel,
    GridRowParams,
    GridToolbar,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Project } from '../../api/sentinel';
import tokenService from '../../authentication/tokenService';
import { EditProjectCell } from './EditProjectCell';
import LocationsCell from './LocationsCell';
import SprintAgeCell from './SprintAgeCell';
import { useEffect, useState } from 'react';
import OverallScoreCell from './OverallScoreCell';
import dateService from '../../services/dateService';
import './personAvatar.css';
import { Person } from '@microsoft/mgt-react';

const ProjectsDataGrid = ({
    projects,
    activeFilter,
    showOverallScore = false,
}: {
    projects: Project[];
    activeFilter: boolean;
    showOverallScore?: boolean;
}) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const translation = {
        sprintAgeInfo: t('projectList_sprintAge_info'),
        overallScoreInfo: t('projectList_overallScore_info'),
    };
    const [columnVisibilityModel, setColumnVisibilityModel] =
        useState<GridColumnVisibilityModel>({
            sprintAge: activeFilter,
        });

    useEffect(() => {
        setColumnVisibilityModel({ sprintAge: activeFilter });
    }, [activeFilter]);

    const handleRowClick = (e: GridRowParams) => {
        navigate(`/Dashboard/${e.row.projectId}`);
    };

    const commonAttributes = {
        flex: 1,
        disableColumnMenu: true,
        filterable: false,
    };

    const field = (
        fieldName: keyof Project | 'grade' | 'edit',
        options?: Partial<GridColDef>,
    ): GridColDef => {
        return {
            field: fieldName,
            headerName: t(`projectList_${fieldName}`),
            ...commonAttributes,
            ...options,
        };
    };

    const engagementModel = (value: string) => {
        if (!value) {
            return null;
        }
        return t(`projectForm_engagementModel__${value.toUpperCase()}`);
    };

    const projectColumns: GridColDef[] = [
        field('projectName', { hideable: false, filterable: true }),
        field('businessId', { filterable: true }),
        field('clientName', { filterable: true, hideable: false }),
        field('managerName', {
            flex: 1.5,
            filterable: true,
        }),
        field('engagementModel', {
            valueGetter: ({ row }) => {
                return engagementModel(row.engagementModel);
            },
            flex: 1.5,
            filterable: true,
        }),
        field('scrumMasters', {
            hideable: false,
            filterable: true,
            valueGetter: ({ row }) => row.scrumMasters?.[0]?.upn,
            resizable: true,
            flex: 1.5,
            renderCell: ({ row }) => {
                const scrumMaster = row.scrumMasters?.[0];
                return (
                    scrumMaster && (
                        <Person
                            view="oneline"
                            className="mgt-person"
                            key={scrumMaster.userId}
                            userId={scrumMaster.userId}
                            personCardInteraction="none"
                            avatarType="photo"
                        />
                    )
                );
            },
        }),
        field('practicePartners', {
            hideable: false,
            filterable: true,
            valueGetter: ({ row }) => row.practicePartners?.[0]?.upn,
            resizable: true,
            flex: 1.5,
            renderCell: ({ row }) => {
                const practicePartner = row.practicePartners?.[0];
                return (
                    practicePartner && (
                        <Person
                            view="oneline"
                            className="mgt-person"
                            key={practicePartner.userId}
                            userId={practicePartner.userId}
                            personCardInteraction="none"
                            avatarType="photo"
                        />
                    )
                );
            },
        }),
        field('startDate', {
            type: 'date',
            valueFormatter: ({ value }) =>
                dateService.toStandardDateFormat(value),
        }),
        field('endDate', {
            type: 'date',
            valueFormatter: ({ value }) =>
                dateService.toStandardDateFormat(value),
        }),
        field('sprintAge', {
            renderCell: SprintAgeCell,
            description: translation.sprintAgeInfo,
            align: 'center',
            headerAlign: 'center',
            filterable: true,
            sortable: true,
            type: 'number',
        }),
        field('overallScore', {
            renderCell: OverallScoreCell,
            description: translation.overallScoreInfo,
            align: 'center',
            headerAlign: 'center',
            filterable: true,
            sortable: true,
            type: 'number',
        }),
        field('locations', {
            renderCell: LocationsCell,
            flex: 2,
        }),
        field('edit', {
            align: 'center',
            headerAlign: 'center',
            sortable: false,
            renderCell: EditProjectCell,
            hideable: tokenService.hasEditingRights(),
        }),
    ];

    if (!showOverallScore) {
        projectColumns.splice(
            projectColumns.findIndex(item => item.field === 'overallScore'),
            1,
        );
    }

    return (
        <DataGrid
            sortingOrder={['desc', 'asc']}
            autoHeight
            columns={projectColumns}
            density={'standard'}
            onRowClick={handleRowClick}
            initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
            rows={projects}
            getRowId={(project: Project) => project.projectId ?? ''}
            pageSizeOptions={[10]}
            slots={{ toolbar: GridToolbar }}
            disableDensitySelector
            slotProps={{
                toolbar: {
                    printOptions: { disableToolbarButton: true },
                    csvOptions: { disableToolbarButton: true },
                },
            }}
            disableVirtualization
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={newModel =>
                setColumnVisibilityModel(newModel)
            }
        />
    );
};

export default ProjectsDataGrid;
