import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Sentinel from './Sentinel.png';

const TopBar = () => {
    return (
        <AppBar position="static" color="default" elevation={0}>
            <Toolbar>
                <Button href={'/'} data-testid="goToHomeButton">
                    <Stack
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                        spacing={1}
                    >
                        <img
                            src={Sentinel}
                            alt="Sentinel Logo"
                            width="50px"
                            height="50px"
                        />
                        <Typography
                            variant="h6"
                            color="primary"
                            component={'div'}
                            sx={{ textTransform: 'none' }}
                        >
                            Sentinel
                        </Typography>
                    </Stack>
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default TopBar;
