import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import theme from '../../theme/theme';

const ProjectLinksHeader = ({
    isEditing,
    onClickAddLink,
}: {
    isEditing: boolean;
    onClickAddLink: () => void;
}) => {
    const { t } = useTranslation();
    const translation = {
        addLink: t('projectLinks_addLink'),
        title: t('projectLinks_title'),
    };

    return (
        <Box>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography
                    fontSize={theme.typography.label.fontSize}
                    paddingTop={'0.22rem'}
                    color={theme.typography.label.color}
                >
                    {translation.title}
                </Typography>
                <Button
                    startIcon={<AddIcon />}
                    onClick={onClickAddLink}
                    disabled={isEditing}
                >
                    {translation.addLink}
                </Button>
            </Stack>
        </Box>
    );
};

export default ProjectLinksHeader;
