export const allowedSprintMetrics = [
    'valueCommitted',
    'valueDelivered',
    'valueReady',
    'totalBacklogSize',
    'workItemsDelivered',
    'teamMorale',
    'totalIssuesOpen',
    'totalIssuesNew',
    'buildPassRate',
    'maxCyclomaticComplexity',
    'staticAnalysisRuleViolations',
    'unitTestCoverage',
    'ciActivity',
    'configurationManagement',
    'deploymentTarget',
    'staticAnalysis',
    'branchingStrategy',
    'releaseCount',
    'remediationCount',
    'productionRelease',
    'iterationGoalSucceeded',
];
