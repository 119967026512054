import WidgetHeader from '../common/widget/WidgetHeader';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetItem from '../common/widget/WidgetItem';
import { useTranslation } from 'react-i18next';
import Donut from '../common/charts/Donut';
import { gradeColorMap } from '../../theme/gradeColor';
import StyledText from '../common/charts/StyledText';
import { Metric } from '../../api/sentinel';

const Predictability = ({
    predictabilityMetric,
}: {
    predictabilityMetric?: Metric & {
        predictabilityTrend?: string;
        velocityVariance?: number;
        velocityVarianceGrade?: string;
        plannedVsActualVelocityRatio?: number;
        plannedVsActualVelocityRatioGrade?: string;
        staticAnalysis?: string;
        staticAnalysisGrade?: string;
        maxCyclomaticComplexity?: number;
        maxCyclomaticComplexityGrade?: string;
    };
}) => {
    const { t: translate } = useTranslation();

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={'"H H"\n"A B"\n"A C"\n"A D"'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={predictabilityMetric?.predictabilityTrend}
                title={translate('predictability_title')}
                subtitle={translate('predictability_subtitle')}
            />

            <WidgetItem
                gridArea={'A'}
                title={translate('predictability_velocityVariance_title')}
                subtitle={translate('predictability_velocityVariance_subtitle')}
                name="velocityVariance"
            >
                <Donut
                    key="velocityVariance"
                    metric={{
                        value: predictabilityMetric?.velocityVariance,
                        grade: predictabilityMetric?.velocityVarianceGrade,
                    }}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'B'}
                title={translate(
                    'predictability_plannedVsActualVelocityRatio_title',
                )}
                subtitle={translate(
                    'predictability_plannedVsActualVelocityRatio_subtitle',
                )}
                name="plannedVsActualVelocityRatio"
            >
                <StyledText
                    color={gradeColorMap.get(
                        predictabilityMetric?.plannedVsActualVelocityRatioGrade,
                    )}
                    primaryText={
                        predictabilityMetric?.plannedVsActualVelocityRatio
                    }
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'C'}
                title={translate('predictability_staticAnalysis_title')}
                subtitle={translate('predictability_staticAnalysis_subtitle')}
                name="staticAnalysis"
            >
                <StyledText
                    color={gradeColorMap.get(
                        predictabilityMetric?.staticAnalysisGrade,
                    )}
                    primaryText={
                        predictabilityMetric?.staticAnalysis === 'Y'
                            ? translate('global_yes')
                            : predictabilityMetric?.staticAnalysis === 'N'
                              ? translate('global_no')
                              : ''
                    }
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'D'}
                title={translate(
                    'predictability_maxCyclomaticComplexity_title',
                )}
                subtitle={translate(
                    'predictability_maxCyclomaticComplexity_subtitle',
                )}
                name="maxCyclomaticComplexity"
            >
                <StyledText
                    color={gradeColorMap.get(
                        predictabilityMetric?.maxCyclomaticComplexityGrade,
                    )}
                    primaryText={predictabilityMetric?.maxCyclomaticComplexity}
                />
            </WidgetItem>
        </WidgetContainer>
    );
};

export default Predictability;
