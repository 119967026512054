import theme from './theme';

const gradeColor = {
    A: theme.intensity.cyan,
    B: theme.intensity.green,
    C: theme.intensity.yellow,
    D: theme.intensity.orange,
    F: theme.intensity.red,
};

const gradeColorMap = new Map();
gradeColorMap.set('A', gradeColor.A);
gradeColorMap.set('B', gradeColor.B);
gradeColorMap.set('C', gradeColor.C);
gradeColorMap.set('D', gradeColor.D);
gradeColorMap.set('F', gradeColor.F);

export { gradeColorMap };

export default gradeColor;
