import { TextFieldProps } from '@mui/material/TextField';
import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import { labelBuilder } from './labelBuilder';
import HelpIconTooltip from './HelpIconTooltip';

const DateInput = (
    props: TextFieldProps & {
        tooltip_title?: string;
        minDate?: Date;
    },
) => {
    const { control } = useFormContext();

    const controller: UseControllerReturn = useController({
        name: props.name as string,
        control,
    });

    return (
        <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                    format="yyyy-MM-dd"
                    label={labelBuilder(props.label as string, props.required)}
                    value={controller.field.value || null}
                    onChange={controller.field.onChange}
                    onAccept={controller.field.onBlur}
                    slotProps={{
                        textField: {
                            inputRef: controller.field.ref,
                            id: `sentinel-date-input-${controller.field.name}`,
                            error: !!controller.fieldState.error,
                            fullWidth: true,
                            helperText: controller.fieldState.error?.message,
                            margin: 'normal',
                            required: false,
                            variant: 'standard',
                        },
                    }}
                    minDate={props.minDate}
                    disabled={props.disabled}
                />
            </LocalizationProvider>
            <HelpIconTooltip title={props.tooltip_title} />
        </Stack>
    );
};

export default DateInput;
