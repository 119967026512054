import { ChangeEvent, useEffect, useState } from 'react';

const useProjectLinksTextField = (
    initValue: string = '',
    validator: (target: string) => string,
) => {
    const [value, setValue] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>();

    useEffect(() => {
        setValue(initValue);
    }, [initValue]);

    const reset = () => {
        setValue('');
        setErrorMessage('');
    };

    const checkIsValid = (targetValue: string): boolean => {
        const errorMessage = validator(targetValue);
        setErrorMessage(errorMessage);
        return !errorMessage;
    };

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        const targetValue = e.currentTarget.value;
        setValue(targetValue);
        checkIsValid(targetValue);
    };

    const isValid = (): boolean => checkIsValid(value);

    return {
        value,
        reset,
        handleOnChange,
        isValid,
        errorMessage,
        setErrorMessage,
    };
};

export default useProjectLinksTextField;
