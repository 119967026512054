import initialValues, { ProjectFormModel } from './services/projectFormModel';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { projectApi } from '../api/sentinel';
import { dtoToForm, formToDto } from './services/projectMapper';
import useLocations from './useLocations';

type UseProjectDataResult = {
    isLoading: boolean;
    isError: boolean;
    error: Error | null;
    formModel: ProjectFormModel;
    save: (model: ProjectFormModel) => Promise<void>;
};

const useProjectData = (
    projectId: string | undefined,
): UseProjectDataResult => {
    const { data, error, isError, isLoading } = useQuery<
        ProjectFormModel,
        Error
    >(['project', projectId], async ({ signal }) => {
        if (projectId !== undefined) {
            return dtoToForm(
                projectId,
                await projectApi.getProject({ projectId }, { signal }),
            );
        } else {
            return { ...initialValues };
        }
    });
    const { data: locations } = useLocations();
    const queryClient = useQueryClient();

    const { mutateAsync } = useMutation({
        mutationFn: async (model: ProjectFormModel) => {
            const project = formToDto(model, locations ?? []);
            if (projectId !== undefined) {
                await projectApi.updateProject({
                    projectId: projectId,
                    project: project,
                });
            } else {
                await projectApi.saveProject({ project });
            }
        },
        onSettled: () => {
            return queryClient.invalidateQueries(['project']);
        },
    });

    return {
        formModel: data || { ...initialValues },
        isLoading,
        isError,
        error,
        save: mutateAsync,
    };
};

export default useProjectData;
