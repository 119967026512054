import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';

const SprintAgeCell = ({ value: sprintAge, row, id }: GridRenderCellParams) => {
    const { t } = useTranslation();
    const translation = {
        notApplicable: t('global_na'),
    };
    const { intensity } = useTheme();
    const isProjectActive = row.active;

    const isInFuture = sprintAge < 0;
    const isBetween0And2Weeks = sprintAge >= 0 && sprintAge < 14;
    const isBetween2And4Weeks = sprintAge >= 14 && sprintAge < 28;

    const getColor = () => {
        if (sprintAge === undefined) {
            return intensity.transparent;
        } else if (isInFuture) {
            return intensity.grey;
        } else if (isBetween0And2Weeks) {
            return intensity.green;
        } else if (isBetween2And4Weeks) {
            return intensity.yellow;
        } else {
            return intensity.red;
        }
    };

    return isProjectActive ? (
        <Box
            id={`sprintAgeCell_${id}`}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            sx={{
                color: intensity.white,
                backgroundColor: getColor(),
                borderRadius: '2rem',
                height: '40px',
                width: '40px',
            }}
        >
            {isProjectActive ? sprintAge : translation.notApplicable}
        </Box>
    ) : (
        <Box>{translation.notApplicable}</Box>
    );
};

export default SprintAgeCell;
