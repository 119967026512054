import NoData from '../../../common/NoData';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { gradeColorMap } from '../../../theme/gradeColor';

export const EscapedDefects = ({
    totalIssuesOpen,
    totalIssuesNew,
    escapedDefectsGrade,
}: {
    totalIssuesOpen?: number;
    totalIssuesNew?: number;
    escapedDefectsGrade?: string;
}) => {
    const { t: translate } = useTranslation();
    const hasEscapedDefects =
        totalIssuesOpen !== undefined || totalIssuesNew !== undefined;
    if (hasEscapedDefects) {
        totalIssuesOpen = totalIssuesOpen ?? 0;
        totalIssuesNew = totalIssuesNew ?? 0;
        const color = gradeColorMap.get(escapedDefectsGrade);
        const rectangleStyle: React.CSSProperties = {
            backgroundColor: color,
            color: 'white',
            // Expands the background to fill the entire width of the parent component
            width: '105%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column' as const,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center' as const,
            fontSize: '1.25rem',
            paddingRight: '0.5em',
        };
        const highlightStyle: React.CSSProperties = {
            fontSize: '2rem',
            fontWeight: 'bold',
            margin: '0 10px',
        };
        return (
            <div style={rectangleStyle}>
                <div>
                    <span style={highlightStyle}>
                        {translate('quality_escapedDefects_new', {
                            newDefects: totalIssuesNew,
                        })}
                    </span>
                    {translate('quality_escapedDefects_new_secondary')}
                </div>
                <div>
                    <span style={highlightStyle}>
                        {translate('quality_escapedDefects_open', {
                            totalOpen: totalIssuesOpen,
                        })}
                    </span>
                    {translate('quality_escapedDefects_open_secondary')}
                </div>
            </div>
        );
    } else {
        return <NoData />;
    }
};
