import Donut from '../../common/charts/Donut';

const FrequencyOfAchievingGoal = ({
    frequencyOfAchievingGoal,
    freqOfAchievingGoalGrade,
}: {
    frequencyOfAchievingGoal?: number;
    freqOfAchievingGoalGrade?: string;
}) => {
    return (
        <Donut
            key="frequencyOfAchievingGoal"
            metric={{
                value: frequencyOfAchievingGoal,
                grade: freqOfAchievingGoalGrade,
            }}
        />
    );
};

export default FrequencyOfAchievingGoal;
