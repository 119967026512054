import { Container } from '@mui/material';
import ReactMarkdown from 'markdown-to-jsx';
import { Fragment, useEffect, useState } from 'react';
import useFetchNotes from './useFetchNotes';
import { useTranslation } from 'react-i18next';
import Paper from '@mui/material/Paper';
import NavBar from './NavBar';
import Typography from '@mui/material/Typography';
import theme from '../theme/theme';
import config from '../services/config';

const ReleaseNotes = () => {
    const manifestFilePath: string | undefined = config.get(
        'PATH_RELEASE_NOTES_MANIFEST',
    );

    const [documents, setDocuments] = useState<string[]>([]);

    const fetchNotes = useFetchNotes(manifestFilePath);

    const { t } = useTranslation();

    const translation = {
        title: t('releaseNotes_title'),
        error_no_release_notes: t('releaseNotes_error_no_documents'),
    };

    useEffect(() => {
        fetchNotes().then((content: string[]) => {
            setDocuments(content);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Fragment>
            <NavBar />
            <Container>
                <Typography
                    fontSize={'2rem'}
                    fontWeight={'bold'}
                    margin={'2rem auto 2rem auto'}
                    color={theme.palette.primary.main}
                >
                    {translation.title}
                </Typography>
                {documents.length
                    ? documents.map((docContent: string, index) => {
                          return (
                              <Paper
                                  key={index}
                                  variant={'outlined'}
                                  sx={{
                                      padding: '2em',
                                  }}
                              >
                                  <ReactMarkdown id={`note-${index}`}>
                                      {docContent}
                                  </ReactMarkdown>
                              </Paper>
                          );
                      })
                    : translation.error_no_release_notes}
            </Container>
        </Fragment>
    );
};

export default ReleaseNotes;
