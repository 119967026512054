import { useNavigate, useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Navbar from './navBar/NavBar';
import NavigationPanel from './navigationPanel/NavigationPanel';
import Productivity from './productivity/Productivity';
import Predictability from './predictability/Predictability';
import Value from './value/Value';
import Responsiveness from './responsiveness/Responsiveness';
import Quality from './quality/Quality';
import Sustainability from './sustainability/Sustainability';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import useDashboardData from './useDashboardData';
import Spinner from '../common/Spinner';
import theme from '../theme/theme';
import { useEffect } from 'react';

const Dashboard = () => {
    const navigate = useNavigate();
    const { projectId = '', sprintId = '' } = useParams();

    const {
        isLoading,
        error,
        data: dashboardData,
    } = useDashboardData(projectId, sprintId);

    useEffect(() => {
        if (
            dashboardData?.selectedSprint?.sprintId &&
            dashboardData.selectedSprint.sprintId.toString() !== sprintId
        ) {
            navigate(
                `/Dashboard/${projectId}/${dashboardData?.selectedSprint?.sprintId}`,
                {
                    replace: true,
                },
            );
        }
    }, [dashboardData, navigate, projectId, sprintId]);

    if (isLoading) {
        return <Spinner open={true} />;
    }
    if (error) {
        return <p>Error: {error.message}</p>;
    }

    return (
        <>
            <Box sx={{ zIndex: 1, position: 'sticky', top: '0px' }}>
                <Navbar
                    projectName={dashboardData?.project.projectName}
                    projectId={projectId}
                />

                <NavigationPanel
                    selectedSprint={dashboardData?.selectedSprint}
                    sprints={dashboardData?.sprints}
                    projectId={projectId}
                    iterationGoalSucceeded={
                        dashboardData?.metricsData?.iterationGoalSucceeded
                    }
                />
            </Box>

            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: theme.palette.grey['100'],
                    padding: '2rem',
                }}
                disableGutters
            >
                <Grid container spacing={4} rowSpacing={4}>
                    <Grid item xs={12} md={6} xl={4}>
                        <Productivity
                            projectId={projectId}
                            sprintId={dashboardData?.selectedSprint?.sprintId}
                            productivityMetrics={dashboardData?.metricsData}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Value valueMetric={dashboardData?.metricsData} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Predictability
                            predictabilityMetric={dashboardData?.metricsData}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Responsiveness
                            responsivenessMetric={dashboardData?.metricsData}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Quality qualityMetric={dashboardData?.metricsData} />
                    </Grid>
                    <Grid item xs={12} md={6} xl={4}>
                        <Sustainability
                            sustainabilityMetric={dashboardData?.metricsData}
                        />
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};

export default Dashboard;
