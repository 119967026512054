import { gradeColorMap } from '../../../theme/gradeColor';
import StyledText from '../../common/charts/StyledText';
import { useTranslation } from 'react-i18next';

const LastReleaseDetails = ({
    lastRelease,
    lastReleaseGrade,
}: {
    lastRelease?: number;
    lastReleaseGrade?: string;
}) => {
    const { t: translate } = useTranslation();

    const isReleaseDuringThisSprint = lastRelease === 0;
    return (
        <StyledText
            color={gradeColorMap.get(lastReleaseGrade)}
            primaryText={
                isReleaseDuringThisSprint
                    ? translate('value_lastRelease_duringThisSprint')
                    : lastRelease
            }
            secondaryText={
                isReleaseDuringThisSprint
                    ? null
                    : translate('value_lastRelease_sprintsAgo')
            }
            primaryTextFontSize={
                isReleaseDuringThisSprint ? '1.5rem' : '2.5rem'
            }
        />
    );
};

export default LastReleaseDetails;
