import ProjectLinksList from './ProjectLinksList';
import ProjectLinksEditor from './ProjectLinksEditor';
import ProjectLinksHeader from './ProjectLinksHeader';
import Box from '@mui/material/Box';
import useProjectLinksPanel from './useProjectLinksPanel';
import { useState } from 'react';
import URLLink from './URLLink';

const ProjectLinksPanel = ({ name }: { name: string }) => {
    const [urlToEdit, setUrlToEdit] = useState<URLLink>();
    const [urlIndexToEdit, setUrlIndexToEdit] = useState<number>();

    const panel = useProjectLinksPanel(name);

    const [isEditing, setIsEditing] = useState(false);

    const handleOnAdd = (name: string, link: string) => {
        panel.addURL(name, link);
        setIsEditing(false);
    };
    const handleOnSaveEdit = (url: URLLink) => {
        panel.editURL(url, urlIndexToEdit);
        resetEditor();
    };

    const handleSelectUrlToEdit = (urlToEdit: URLLink, indexToEdit: number) => {
        setUrlIndexToEdit(indexToEdit);
        setUrlToEdit(urlToEdit);
        setIsEditing(true);
    };

    const handleCancel = () => {
        resetEditor();
    };

    const resetEditor = () => {
        setUrlToEdit(undefined);
        setUrlIndexToEdit(undefined);
        setIsEditing(false);
    };

    return (
        <Box>
            <ProjectLinksHeader
                isEditing={isEditing}
                onClickAddLink={() => setIsEditing(true)}
            />
            {isEditing ? (
                <ProjectLinksEditor
                    onEnable={isEditing}
                    urlToEdit={urlToEdit}
                    onAdd={(name: string, link: string) =>
                        handleOnAdd(name, link)
                    }
                    onEdit={url => handleOnSaveEdit(url)}
                    onCancel={handleCancel}
                />
            ) : (
                <ProjectLinksList
                    urls={panel.projectLinksField.value}
                    onEdit={(urlToEdit, index) =>
                        handleSelectUrlToEdit(urlToEdit, index)
                    }
                    onDelete={panel.deleteURL}
                />
            )}
        </Box>
    );
};

export default ProjectLinksPanel;
