import {
    Cell,
    Label,
    Pie,
    PieChart,
    ResponsiveContainer,
    Sector,
} from 'recharts';
import { gradeColorMap } from '../../../theme/gradeColor';
import theme from '../../../theme/theme';
import NoData from '../../../common/NoData';

const GaugeChart = ({
    value,
    grade,
    deltas,
    cy,
    height,
    gaugeChartName,
}: {
    value?: number;
    grade?: string;
    deltas: {
        f: number;
        d: number;
        c: number;
        b: number;
        a: number;
    };
    cy: string;
    height: number;
    gaugeChartName: string;
}) => {
    if (!value && value !== 0) {
        return <NoData />;
    }

    const colorData = Object.entries(deltas).map(([key, value]) => {
        return {
            name: key,
            value,
            color: gradeColorMap.get(key.toUpperCase()),
        };
    });

    const activeSectorIndex = colorData
        .map((cur, index, arr) => {
            const curMax = [...arr].splice(0, index + 1).reduce((a, b) => ({
                name: a.name,
                value: a.value + b.value,
                color: a.color,
            })).value;
            return value > curMax - cur.value && value <= curMax;
        })
        .findIndex(cur => cur);

    const sumValues = colorData.map(cur => cur.value).reduce((a, b) => a + b);

    const arrowData = [
        { value: value },
        { value: 0 },
        { value: sumValues - value },
    ];

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        paddingAngle: 0,
        cy: cy,
    };

    const pieRadius = {
        innerRadius: '73%',
        outerRadius: '85%',
    };

    const Arrow = ({
        cx,
        cy,
        midAngle,
    }: {
        cx: number;
        cy: number;
        midAngle: number;
    }) => {
        //eslint-disable-line react/no-multi-comp
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + 0.4 * height * cos;
        const my = cy + 0.4 * height * sin;
        return (
            <g id={'sentinel-gaugeChart-' + gaugeChartName}>
                <circle
                    cx={cx}
                    cy={cy}
                    r={`${0.008 * height}%`}
                    fill={theme.palette.common.black}
                    stroke="none"
                />
                <path
                    d={`M${cx},${cy}L${mx},${my}`}
                    strokeWidth="3"
                    stroke={theme.palette.common.black}
                    fill="none"
                    strokeLinecap="round"
                />
            </g>
        );
    };

    const ActiveSectorMark = ({
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
    }: {
        cx: number;
        cy: number;
        innerRadius: number;
        outerRadius: number;
        startAngle: number;
        endAngle: number;
        fill: string;
    }) => {
        //eslint-disable-line react/no-multi-comp
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius * 1.2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    return (
        // The container is only responsive with 99% width. Check the following link: https://stackoverflow.com/a/53205850
        <ResponsiveContainer width={'99%'} height={height}>
            <PieChart
                margin={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                }}
            >
                <Pie
                    activeIndex={activeSectorIndex}
                    activeShape={ActiveSectorMark}
                    data={colorData}
                    dataKey="value"
                    fill="#8884d8"
                    {...pieRadius}
                    {...pieProps}
                    isAnimationActive={true}
                >
                    {colorData.map((_, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colorData[index].color}
                        />
                    ))}
                </Pie>
                <Pie
                    stroke="none"
                    activeIndex={1}
                    activeShape={Arrow}
                    data={arrowData}
                    outerRadius={pieRadius.innerRadius}
                    fill="none"
                    {...pieProps}
                    dataKey="value"
                    isAnimationActive={true}
                    animationDuration={10000}
                >
                    <Label
                        value={`${value}%`}
                        position="bottom"
                        fontSize="min(1.25rem, 1.5vw)"
                        style={{
                            fontWeight: 500,
                            fill: gradeColorMap.get(grade),
                        }}
                    />
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default GaugeChart;
