import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import { labelBuilder } from './labelBuilder';
import { InputProps } from '@mui/material/Input/Input';
import HelpIconTooltip from './HelpIconTooltip';

export interface Iprops {
    helper_text?: string;
    label?: string;
    name: string;
    placeholder?: string;
    tooltip_title?: string;
}

const TextFieldInput = (props: Iprops & InputProps) => {
    const { control } = useFormContext();
    const { label, ...otherInputProps } = props;

    const controller: UseControllerReturn = useController({
        name: props.name,
        control,
    });
    return (
        <FormControl
            fullWidth
            error={!!controller.fieldState.error}
            margin="normal"
            variant="standard"
        >
            <InputLabel
                htmlFor={`sentinel-text-input-${controller.field.name}`}
                id={`sentinel-text-label-${controller.field.name}`}
                shrink
            >
                {labelBuilder(label as string, otherInputProps.required)}
            </InputLabel>
            <Input
                {...otherInputProps}
                required={false}
                inputRef={controller.field.ref}
                id={`sentinel-text-input-${controller.field.name}`}
                name={controller.field.name}
                value={controller.field.value}
                onChange={controller.field.onChange}
                onBlur={controller.field.onBlur}
                endAdornment={
                    props.tooltip_title && (
                        <InputAdornment position="end">
                            <HelpIconTooltip title={props.tooltip_title} />
                        </InputAdornment>
                    )
                }
                data-testid={controller.field.name}
            />
            {!!controller.fieldState.error && (
                <FormHelperText>
                    {controller.fieldState.error?.message}
                </FormHelperText>
            )}
            {!controller.fieldState.error && props.helper_text && (
                <FormHelperText>{props.helper_text}</FormHelperText>
            )}
        </FormControl>
    );
};

export default TextFieldInput;
