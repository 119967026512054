import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

export default function Spinner({ open }: { open: boolean }) {
    return (
        <Backdrop open={open}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
}
