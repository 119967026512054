import React from 'react';
import { Navigate } from 'react-router-dom';
import tokenService from './authentication/tokenService';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    if (!tokenService.hasReadRights()) {
        return <Navigate to={'/RequestAccess'} replace />;
    }

    return children;
};
export default ProtectedRoute;
