import { InteractionStatus } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { loginRequest } from './authConfig';
import tokenService from './tokenService';

export default function (usingMsal: boolean) {
    const isAuthenticated = useIsAuthenticated();
    const { inProgress, instance } = useMsal();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (usingMsal && instance) {
            tokenService.setMsalInstance(instance);
        }
    }, [instance, usingMsal]);

    useEffect(() => {
        async function init(): Promise<string | undefined> {
            if (
                (usingMsal &&
                    inProgress === InteractionStatus.None &&
                    !isAuthenticated) ||
                (isAuthenticated && tokenService.isExpired())
            ) {
                await instance
                    .loginRedirect(loginRequest)
                    .catch(error => console.error(error));
                await tokenService.setGraphToken();
                return tokenService.getToken();
            } else if (isAuthenticated && !tokenService.isExpired()) {
                await tokenService.setGraphToken();
                return await tokenService.refreshToken();
            }
        }

        init().then(token => {
            setIsReady(!!token);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inProgress, isAuthenticated, usingMsal]);

    return { isReady };
}
