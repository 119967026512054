import theme from '../theme/theme';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
    const previousPage = -1;

    const navigate = useNavigate();
    const { t } = useTranslation();

    const translation = {
        back: t('global_back'),
    };

    const handleBackButtonClick = () => {
        navigate(previousPage);
    };

    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                padding: '0 3%',
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <IconButton
                        aria-label={'back_button'}
                        onClick={() => handleBackButtonClick()}
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography
                        variant="h1"
                        component="h1"
                        mb="0"
                        ml="10px"
                        color={theme.palette.primary.main}
                    >
                        {translation.back}
                    </Typography>
                </Stack>
            </Stack>
        </Container>
    );
};

export default NavBar;
