import {
    Box,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { useRef, useState } from 'react';
import ConfirmationPanel from './ConfirmationPanel';
import URLLink from './URLLink';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import theme from '../../theme/theme';
import ProjectLinksEmptyBox from './ProjectLinksEmptyBox';

const ProjectLinkList = ({
    urls,
    onEdit,
    onDelete,
}: {
    urls: URLLink[];
    onEdit: (url: URLLink, index: number) => void;
    onDelete: (index: number) => void;
}) => {
    const [openConfirmationPanel, setOpenConfirmationPanel] = useState(false);
    const [urlIndexToDelete, setUrlIndexToDelete] = useState<number>();
    const anchorRef = useRef<HTMLElement>(document.body);

    const [disableButtons, setDisableButtons] = useState(false);

    const handleClickDelete = (index: number) => {
        setUrlIndexToDelete(index);
        setDisableButtons(true);
        setOpenConfirmationPanel(true);
    };

    const closeDialog = () => setOpenConfirmationPanel(false);

    const deleteURL = () => {
        if (urlIndexToDelete !== undefined) {
            onDelete(urlIndexToDelete);
        }
    };

    const handleConfirm = () => {
        deleteURL();
        closeDialog();
    };

    const handleClickEdit = (editedUrl: URLLink, index: number) => {
        setDisableButtons(true);
        onEdit(editedUrl, index);
    };

    const handleTransitionExited = () => {
        setUrlIndexToDelete(undefined);
        setDisableButtons(false);
    };
    return (
        <Box ref={anchorRef} data-testid="useful-links-list">
            <ConfirmationPanel
                anchor={anchorRef.current}
                handleTransitionExited={handleTransitionExited}
                message={
                    urlIndexToDelete !== undefined
                        ? urls[urlIndexToDelete]?.name
                        : ''
                }
                onConfirm={handleConfirm}
                onCancel={closeDialog}
                open={openConfirmationPanel}
            />

            {urls.length > 0 ? (
                <TableContainer component={Paper} variant={'outlined'}>
                    <Table>
                        <TableBody>
                            {urls.map((url, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        ':hover': {
                                            backgroundColor:
                                                theme.palette.background
                                                    .default,
                                        },
                                        transitionDuration: '0.32s',
                                    }}
                                >
                                    <TableCell
                                        scope="row"
                                        padding={'none'}
                                        sx={{ paddingLeft: '1rem' }}
                                    >
                                        <Link
                                            href={url.url}
                                            target={'_blank'}
                                            underline={'hover'}
                                        >
                                            {url.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        padding={'none'}
                                        sx={{ paddingRight: '1rem' }}
                                    >
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={() =>
                                                handleClickEdit(url, index)
                                            }
                                            sx={{ marginRight: '1rem' }}
                                            disabled={disableButtons}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={() =>
                                                handleClickDelete(index)
                                            }
                                            disabled={disableButtons}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <ProjectLinksEmptyBox />
            )}
        </Box>
    );
};

export default ProjectLinkList;
