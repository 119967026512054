// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Project } from '../models/index';
import { ProjectFromJSON, ProjectToJSON } from '../models/index';

export interface FindProjectsRequest {
    status?: string;
    locationId?: number;
}

export interface GetProjectRequest {
    projectId: string;
}

export interface SaveProjectRequest {
    project: Project;
}

export interface UpdateProjectRequest {
    projectId: string;
    project: Project;
}

/**
 *
 */
export class ProjectApi extends runtime.BaseAPI {
    /**
     */
    async findProjectsRaw(
        requestParameters: FindProjectsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Project>>> {
        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.locationId !== undefined) {
            queryParameters['locationId'] = requestParameters.locationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(ProjectFromJSON),
        );
    }

    /**
     */
    async findProjects(
        requestParameters: FindProjectsRequest = {},
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Project>> {
        const response = await this.findProjectsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async getProjectRaw(
        requestParameters: GetProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Project>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getProject.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            ProjectFromJSON(jsonValue),
        );
    }

    /**
     */
    async getProject(
        requestParameters: GetProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Project> {
        const response = await this.getProjectRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async saveProjectRaw(
        requestParameters: SaveProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Project>> {
        if (
            requestParameters.project === null ||
            requestParameters.project === undefined
        ) {
            throw new runtime.RequiredError(
                'project',
                'Required parameter requestParameters.project was null or undefined when calling saveProject.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ProjectToJSON(requestParameters.project),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            ProjectFromJSON(jsonValue),
        );
    }

    /**
     */
    async saveProject(
        requestParameters: SaveProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Project> {
        const response = await this.saveProjectRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updateProjectRaw(
        requestParameters: UpdateProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Project>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling updateProject.',
            );
        }

        if (
            requestParameters.project === null ||
            requestParameters.project === undefined
        ) {
            throw new runtime.RequiredError(
                'project',
                'Required parameter requestParameters.project was null or undefined when calling updateProject.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: ProjectToJSON(requestParameters.project),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            ProjectFromJSON(jsonValue),
        );
    }

    /**
     */
    async updateProject(
        requestParameters: UpdateProjectRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Project> {
        const response = await this.updateProjectRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
