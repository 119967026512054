import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ValidationError } from '../services/errors/ValidationError';

const useResultToasts = () => {
    const { t } = useTranslation();
    const translation = {
        successToast: t('projectForm_success_toast'),
        errorToast: t('projectForm_error_toast'),
    };

    const successToast = () => {
        toast.success(translation.successToast, {
            toastId: 'sentinel-toast-project-success',
        });
    };

    const failureToast = (e: unknown) => {
        if (e instanceof ValidationError) {
            // we can potentially set CreateProjectForm errors here
            toast.warning('BACKEND_VALIDATION_ERROR', {
                toastId: 'sentinel-toast-backend-validation-warning',
            });
            e.errors.forEach((errorDetails, index) => {
                toast.error(errorDetails.location, {
                    toastId:
                        'sentinel-toast-backend-validation-warning-' + index,
                });
            });
        } else {
            toast.error(translation.errorToast, {
                toastId: 'sentinel-toast-project-failure',
            });
        }
    };

    return { successToast, failureToast };
};

export default useResultToasts;
