import WidgetHeader from '../common/widget/WidgetHeader';
import GaugeChart from '../common/charts/GaugeChart';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetItem from '../common/widget/WidgetItem';
import { useTranslation } from 'react-i18next';
import StyledText from '../common/charts/StyledText';
import { gradeColorMap } from '../../theme/gradeColor';
import { Metric } from '../../api/sentinel';
import DeploymentTarget from './charts/DeploymentTarget';

const Responsiveness = ({
    responsivenessMetric,
}: {
    responsivenessMetric?: Metric & {
        responsivenessTrend?: string;
        deploymentTarget?: string;
        deploymentTargetGrade?: string;
        buildPassRate?: number;
        buildPassRateGrade?: string;
        configurationManagement?: string;
        configurationManagementGrade?: string;
    };
}) => {
    const { t: translate } = useTranslation();

    const configurationManagementText =
        responsivenessMetric?.configurationManagement === 'Y'
            ? translate('global_yes')
            : responsivenessMetric?.configurationManagement === 'N'
              ? translate('global_no')
              : '';

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={'"H H"\n"A B"\n"A C"'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={responsivenessMetric?.responsivenessTrend}
                title={translate('responsiveness_title')}
                subtitle={translate('responsiveness_subtitle')}
            />

            <WidgetItem
                gridArea={'A'}
                title={translate('responsiveness_deploymentTarget_title')}
                subtitle={translate('responsiveness_deploymentTarget_subtitle')}
                name="deploymentTarget"
            >
                <DeploymentTarget
                    value={responsivenessMetric?.deploymentTarget}
                    grade={responsivenessMetric?.deploymentTargetGrade}
                />
            </WidgetItem>

            <WidgetItem
                gridArea={'B'}
                title={translate('responsiveness_buildPassRate_title')}
                subtitle={translate('responsiveness_buildPassRate_subtitle')}
                name="buildPassRate"
            >
                <GaugeChart
                    value={responsivenessMetric?.buildPassRate}
                    grade={responsivenessMetric?.buildPassRateGrade}
                    deltas={{
                        f: 29.99,
                        d: 20,
                        c: 25,
                        b: 15,
                        a: 10.01,
                    }}
                    cy="70%"
                    height={250}
                    gaugeChartName="buildPassRate"
                />
            </WidgetItem>

            <WidgetItem
                gridArea={'C'}
                title={translate('responsiveness_configManagement_title')}
                subtitle={translate('responsiveness_configManagement_subtitle')}
                name="configManagement"
            >
                <StyledText
                    color={gradeColorMap.get(
                        responsivenessMetric?.configurationManagementGrade,
                    )}
                    primaryText={configurationManagementText}
                />
            </WidgetItem>
        </WidgetContainer>
    );
};
export default Responsiveness;
