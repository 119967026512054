class ValidationError extends Error {
    errors: ErrorDetails[];
    constructor(message: string | undefined, errors: ErrorDetails[] = []) {
        super(message);
        this.name = 'ValidationError';
        this.errors = errors;
    }
}

export { ValidationError };
