// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Location
 */
export interface Location {
    /**
     *
     * @type {number}
     * @memberof Location
     */
    locationId?: number;
    /**
     *
     * @type {string}
     * @memberof Location
     */
    location?: string;
    /**
     *
     * @type {string}
     * @memberof Location
     */
    createdBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Location
     */
    createdOn?: Date;
    /**
     *
     * @type {string}
     * @memberof Location
     */
    updatedBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Location
     */
    updatedOn?: Date;
}

/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Location {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        locationId: !exists(json, 'locationId')
            ? undefined
            : json['locationId'],
        location: !exists(json, 'location') ? undefined : json['location'],
        createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
        createdOn: !exists(json, 'createdOn')
            ? undefined
            : new Date(json['createdOn']),
        updatedBy: !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        updatedOn: !exists(json, 'updatedOn')
            ? undefined
            : new Date(json['updatedOn']),
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        locationId: value.locationId,
        location: value.location,
        createdBy: value.createdBy,
        createdOn:
            value.createdOn === undefined
                ? undefined
                : value.createdOn.toISOString(),
        updatedBy: value.updatedBy,
        updatedOn:
            value.updatedOn === undefined
                ? undefined
                : value.updatedOn.toISOString(),
    };
}
