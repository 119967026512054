// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Restriction } from './Restriction';
import {
    RestrictionFromJSON,
    RestrictionFromJSONTyped,
    RestrictionToJSON,
} from './Restriction';
import type { ValueConstraint } from './ValueConstraint';
import {
    ValueConstraintFromJSON,
    ValueConstraintFromJSONTyped,
    ValueConstraintToJSON,
} from './ValueConstraint';

/**
 *
 * @export
 * @interface ProvidedMetricSpec
 */
export interface ProvidedMetricSpec {
    /**
     *
     * @type {string}
     * @memberof ProvidedMetricSpec
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof ProvidedMetricSpec
     */
    relationship?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof ProvidedMetricSpec
     */
    options?: Array<string>;
    /**
     *
     * @type {ValueConstraint}
     * @memberof ProvidedMetricSpec
     */
    valueConstraint?: ValueConstraint;
    /**
     *
     * @type {Restriction}
     * @memberof ProvidedMetricSpec
     */
    restriction?: Restriction;
}

/**
 * Check if a given object implements the ProvidedMetricSpec interface.
 */
export function instanceOfProvidedMetricSpec(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProvidedMetricSpecFromJSON(json: any): ProvidedMetricSpec {
    return ProvidedMetricSpecFromJSONTyped(json, false);
}

export function ProvidedMetricSpecFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ProvidedMetricSpec {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, 'type') ? undefined : json['type'],
        relationship: !exists(json, 'relationship')
            ? undefined
            : json['relationship'],
        options: !exists(json, 'options') ? undefined : json['options'],
        valueConstraint: !exists(json, 'value-constraint')
            ? undefined
            : ValueConstraintFromJSON(json['value-constraint']),
        restriction: !exists(json, 'restriction')
            ? undefined
            : RestrictionFromJSON(json['restriction']),
    };
}

export function ProvidedMetricSpecToJSON(
    value?: ProvidedMetricSpec | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: value.type,
        relationship: value.relationship,
        options: value.options,
        'value-constraint': ValueConstraintToJSON(value.valueConstraint),
        restriction: RestrictionToJSON(value.restriction),
    };
}
