import URLLink from './URLLink';
import { useController, useFormContext } from 'react-hook-form';

const useProjectLinksPanel = (name: string) => {
    const { control, setValue, getValues } = useFormContext();
    const { field: projectLinksField } = useController({ name, control });

    const addURL = (name: string, link: string) => {
        projectLinksField.onChange([
            ...projectLinksField.value,
            {
                name: name,
                url: link,
            },
        ]);
    };

    const editURL = (editedUrl: URLLink, index?: number) => {
        if (index === undefined) {
            return;
        }

        const urlLinks: URLLink[] = projectLinksField.value;

        urlLinks[index].name = editedUrl.name;
        urlLinks[index].url = editedUrl.url;

        projectLinksField.onChange(urlLinks);
    };

    const deleteURL = (urlIndexToDelete: number) => {
        const urlLinks: URLLink[] = getValues(name);
        const newArray = urlLinks.filter(
            (_, index) => index !== urlIndexToDelete,
        );
        setValue(name, newArray, { shouldDirty: true });
    };

    return {
        addURL,
        deleteURL,
        editURL,
        projectLinksField,
    };
};

export default useProjectLinksPanel;
