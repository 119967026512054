// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { BurnupChartResponse } from '../models/index';
import {
    BurnupChartResponseFromJSON,
    BurnupChartResponseToJSON,
} from '../models/index';

export interface GetBurnupChartRequest {
    projectId: string;
    sprintId: number;
}

/**
 *
 */
export class BurnupChartApi extends runtime.BaseAPI {
    /**
     */
    async getBurnupChartRaw(
        requestParameters: GetBurnupChartRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<BurnupChartResponse>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getBurnupChart.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling getBurnupChart.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/burnupchart`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            BurnupChartResponseFromJSON(jsonValue),
        );
    }

    /**
     */
    async getBurnupChart(
        requestParameters: GetBurnupChartRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<BurnupChartResponse> {
        const response = await this.getBurnupChartRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
