import addDays from 'date-fns/addDays';
import { User , ProjectLink } from '../../api/sentinel';

export type EngagementModel =
    | ''
    | 'CAPACITY'
    | 'OUTCOME'
    | 'COMANAGED'
    | 'ASSESSMENT';

export type ProjectFormModel = {
    activeProjectSwitch: boolean;
    businessId: string;
    clientName: string;
    engagementManager: string;
    endDate: Date;
    locations: string[];
    projectId?: string;
    projectName: string;
    startDate: Date;
    engagementModel: EngagementModel;
    scrumMasters: Array<User>;
    practicePartners: Array<User>;
    projectLinks: Array<ProjectLink>;
};

const projectFormModel: ProjectFormModel = {
    activeProjectSwitch: true,
    businessId: '',
    clientName: '',
    engagementManager: '',
    endDate: addDays(new Date(), 1),
    locations: [],
    projectId: undefined,
    projectName: '',
    startDate: new Date(),
    engagementModel: '',
    scrumMasters: [],
    practicePartners: [],
    projectLinks: [],
};

export default projectFormModel;
