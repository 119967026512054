// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Restriction
 */
export interface Restriction {
    /**
     *
     * @type {string}
     * @memberof Restriction
     */
    readAccessRole?: string;
    /**
     *
     * @type {string}
     * @memberof Restriction
     */
    writeAccessRole?: string;
}

/**
 * Check if a given object implements the Restriction interface.
 */
export function instanceOfRestriction(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RestrictionFromJSON(json: any): Restriction {
    return RestrictionFromJSONTyped(json, false);
}

export function RestrictionFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Restriction {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        readAccessRole: !exists(json, 'readAccessRole')
            ? undefined
            : json['readAccessRole'],
        writeAccessRole: !exists(json, 'writeAccessRole')
            ? undefined
            : json['writeAccessRole'],
    };
}

export function RestrictionToJSON(value?: Restriction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        readAccessRole: value.readAccessRole,
        writeAccessRole: value.writeAccessRole,
    };
}
