import { useQuery } from 'react-query';
import { DashboardData } from './services/dashboardData';
import dashboardService from './services/dashboardService';
import { safeParseInt } from '../utils/util';

type UseDashboardDataResult = {
    isLoading: boolean;
    error: Error | null;
    data?: DashboardData;
};

const useDashboardData = (
    projectId?: string,
    sprintId?: string,
): UseDashboardDataResult => {
    return useQuery<DashboardData, Error>(
        `dashboard-${projectId}-${sprintId}`,
        async () => {
            if (projectId) {
                return dashboardService.getById(
                    projectId,
                    safeParseInt(sprintId),
                );
            } else {
                return Promise.reject(new Error('projectId is undefined'));
            }
        },
        {
            cacheTime: 0,
            staleTime: 0,
        },
    );
};

export default useDashboardData;
