import Morale from './charts/Morale';
import WidgetHeader from '../common/widget/WidgetHeader';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetItem from '../common/widget/WidgetItem';
import { useTranslation } from 'react-i18next';
import StyledText from '../common/charts/StyledText';
import { Metric } from '../../api/sentinel';
import { gradeColorMap } from '../../theme/gradeColor';

const getBranchingStrategyFontSize = (value?: string) => {
    if (value !== 'Feature, Branch Per Release') {
        return '2rem';
    } else {
        return '1.5rem';
    }
};

const Sustainability = ({
    sustainabilityMetric,
}: {
    sustainabilityMetric?: Metric & {
        sustainabilityTrend?: string;
        teamMorale?: string;
        teamMoraleGrade?: string;
        branchingStrategy?: string;
        branchingStrategyGrade?: string;
        staticAnalysisRuleViolations?: number;
        staticAnalysisRuleViolationsGrade?: string;
        readyVsTeamVelocityRatio?: number;
        readyVsTeamVelocityRatioGrade?: string;
    };
}) => {
    const { t: translate } = useTranslation();

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={'"H H"\n"A C"\n"B D"'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={sustainabilityMetric?.sustainabilityTrend}
                title={translate('sustainability_title')}
                subtitle={translate('sustainability_subtitle')}
            />
            <WidgetItem
                gridArea={'A'}
                title={translate('sustainability_teamMorale_title')}
                subtitle={translate('sustainability_teamMorale_subtitle')}
                name="teamMorale"
            >
                <Morale
                    value={sustainabilityMetric?.teamMorale}
                    grade={sustainabilityMetric?.teamMoraleGrade}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'B'}
                title={translate('sustainability_branchingStrategy_title')}
                subtitle={translate(
                    'sustainability_branchingStrategy_subtitle',
                )}
                name="branchingStrategy"
            >
                <StyledText
                    color={gradeColorMap.get(
                        sustainabilityMetric?.branchingStrategyGrade,
                    )}
                    primaryText={sustainabilityMetric?.branchingStrategy}
                    primaryTextFontSize={getBranchingStrategyFontSize(
                        sustainabilityMetric?.branchingStrategy,
                    )}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'C'}
                title={translate(
                    'sustainability_staticAnalysisRuleViolations_title',
                )}
                subtitle={translate(
                    'sustainability_staticAnalysisRuleViolations_subtitle',
                )}
                name="staticAnalysisRuleViolations"
            >
                <StyledText
                    color={gradeColorMap.get(
                        sustainabilityMetric?.staticAnalysisRuleViolationsGrade,
                    )}
                    primaryText={sustainabilityMetric?.staticAnalysisRuleViolations?.toString()}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'D'}
                title={translate(
                    'sustainability_readyVsTeamVelocityRatio_title',
                )}
                subtitle={translate(
                    'sustainability_readyVsTeamVelocityRatio_subtitle',
                )}
                name="readyVsTeamVelocityRatio"
            >
                <StyledText
                    color={gradeColorMap.get(
                        sustainabilityMetric?.readyVsTeamVelocityRatioGrade,
                    )}
                    primaryText={sustainabilityMetric?.readyVsTeamVelocityRatio?.toString()}
                />
            </WidgetItem>
        </WidgetContainer>
    );
};
export default Sustainability;
