import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
    ProtocolMode,
    RedirectRequest,
} from '@azure/msal-browser';
import config from '../services/config';

const getPcaConfig = () => ({
    auth: {
        clientId: config.get('REACT_APP_AUTH_CLIENT_ID') as string,
        redirectUri: config.get('REACT_APP_AUTH_REDIRECT_URI') as string,
        authority: config.get('REACT_APP_AUTH_AUTHORITY') as string,
        knownAuthorities: [config.get('REACT_APP_AUTH_AUTHORITY') as string],
        protocolMode: ProtocolMode.OIDC,
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: true,
    },
});

const pca = new PublicClientApplication(getPcaConfig());

const loginSuccessCallback = (event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        pca.setActiveAccount(account);
    }
};

pca.addEventCallback(loginSuccessCallback);

const loginRequest: RedirectRequest = {
    scopes: [config.get('REACT_APP_AUTH_SCOPES') as string],
};

export {
    loginSuccessCallback,
    loginRequest,
    pca as publicClientApplication,
    getPcaConfig,
};
