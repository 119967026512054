import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import Switch from '@mui/material/Switch';
import { labelBuilder } from './labelBuilder';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import HelpIconTooltip from './HelpIconTooltip';

const SwitchInput = (
    props: CheckboxProps & {
        name: string;
        label: string;
        tooltip_title?: string;
    },
) => {
    const controller: UseControllerReturn = useController({
        name: props.name,
        control: useFormContext().control,
    });

    return (
        <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <FormControl
                fullWidth
                error={!!controller.fieldState.error}
                margin="normal"
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={controller.field.value}
                                onChange={controller.field.onChange}
                                onBlur={controller.field.onBlur}
                                value={controller.field.value}
                                name={props.name}
                                required={false}
                                disabled={props.disabled}
                                data-testid={props.name}
                                id={'sentinel-switch-input-' + props.name}
                            />
                        }
                        label={labelBuilder(props.label, props.required)}
                    />
                </FormGroup>
            </FormControl>
            <HelpIconTooltip title={props.tooltip_title} />
        </Stack>
    );
};

export default SwitchInput;
