import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox';
import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import { labelBuilder } from './labelBuilder';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import HelpIconTooltip from './HelpIconTooltip';

const SingleCheckbox = (
    props: CheckboxProps & {
        name: string;
        label: string;
        tooltip_title?: string;
    },
) => {
    const controller: UseControllerReturn = useController({
        name: props.name,
        control: useFormContext().control,
    });

    return (
        <Stack
            alignItems="baseline"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <FormControl
                fullWidth
                error={!!controller.fieldState.error}
                margin="normal"
            >
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                id={
                                    'sentinel-checkbox-field-' +
                                    controller.field.name
                                }
                                checked={controller.field.value}
                                onChange={controller.field.onChange}
                                onBlur={controller.field.onBlur}
                                name={controller.field.name}
                                value={true}
                                required={false}
                                disabled={props.disabled}
                                data-testid={
                                    'sentinel-checkbox-field-' +
                                    controller.field.name
                                }
                            />
                        }
                        label={labelBuilder(props.label, props.required)}
                    />
                </FormGroup>
            </FormControl>
            {props.tooltip_title && (
                <InputAdornment position="end">
                    <HelpIconTooltip title={props.tooltip_title} />
                </InputAdornment>
            )}
        </Stack>
    );
};

export default SingleCheckbox;
