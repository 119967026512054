import { string as yupString } from 'yup';
import { useTranslation } from 'react-i18next';

const useProjectLinksValidator = () => {
    const { t } = useTranslation();
    const translation = {
        nameFieldEmpty: t('projectLinks_error_name_field_empty'),
        linkFieldEmpty: t('projectLinks_error_link_field_empty'),
        failedEdit: t('projectLinks_error_edit_went_wrong'),
        errorNotALink: t('projectLinks_error_link_field_not_a_link'),
    };

    const nameValidator = (value: string) => {
        if (!value) {
            return translation.nameFieldEmpty;
        }

        return '';
    };

    const urlValidator = (value: string) => {
        const urlSchema = yupString().url();

        if (!value) {
            return translation.linkFieldEmpty;
        } else if (!urlSchema.isValidSync(value)) {
            return translation.errorNotALink;
        }

        return '';
    };

    return {
        nameValidator,
        urlValidator,
    };
};

export default useProjectLinksValidator;
