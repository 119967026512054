// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ProjectLink
 */
export interface ProjectLink {
    /**
     *
     * @type {number}
     * @memberof ProjectLink
     */
    linkId?: number;
    /**
     *
     * @type {string}
     * @memberof ProjectLink
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLink
     */
    url?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectLink
     */
    createdBy?: string;
    /**
     *
     * @type {Date}
     * @memberof ProjectLink
     */
    createdOn?: Date;
    /**
     *
     * @type {string}
     * @memberof ProjectLink
     */
    updatedBy?: string;
    /**
     *
     * @type {Date}
     * @memberof ProjectLink
     */
    updatedOn?: Date;
}

/**
 * Check if a given object implements the ProjectLink interface.
 */
export function instanceOfProjectLink(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProjectLinkFromJSON(json: any): ProjectLink {
    return ProjectLinkFromJSONTyped(json, false);
}

export function ProjectLinkFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ProjectLink {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        linkId: !exists(json, 'linkId') ? undefined : json['linkId'],
        name: !exists(json, 'name') ? undefined : json['name'],
        url: !exists(json, 'url') ? undefined : json['url'],
        createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
        createdOn: !exists(json, 'createdOn')
            ? undefined
            : new Date(json['createdOn']),
        updatedBy: !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        updatedOn: !exists(json, 'updatedOn')
            ? undefined
            : new Date(json['updatedOn']),
    };
}

export function ProjectLinkToJSON(value?: ProjectLink | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        linkId: value.linkId,
        name: value.name,
        url: value.url,
        createdBy: value.createdBy,
        createdOn:
            value.createdOn === undefined
                ? undefined
                : value.createdOn.toISOString(),
        updatedBy: value.updatedBy,
        updatedOn:
            value.updatedOn === undefined
                ? undefined
                : value.updatedOn.toISOString(),
    };
}
