import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const ModalAppBar = ({
    dialogBody,
    dialogTitle,
    onClose,
    showWarnings,
    title,
}: {
    dialogBody: string;
    dialogTitle: string;
    onClose: () => void;
    showWarnings: boolean;
    title: string;
}) => {
    const { t } = useTranslation();
    const translation = {
        btnCancel: t('topBar_cancelBtn'),
        btnRemove: t('topBar_removeBtn'),
    };
    const [isDialogOpen, setIsDialogOpen] = React.useState(false);

    const confirm = () => {
        if (showWarnings) {
            setIsDialogOpen(true);
        } else {
            onClose();
        }
    };

    const cancel = () => {
        setIsDialogOpen(false);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        onClose();
    };

    return (
        <>
            <AppBar position="sticky" color="default" elevation={0}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="primary"
                        aria-label="close"
                        size="small"
                        onClick={confirm}
                        data-testid="backButton"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography>{title}</Typography>
                </Toolbar>
            </AppBar>
            <Divider />
            <Dialog
                open={isDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" data-testid="dialogTitle">
                    {dialogTitle}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        id="alert-dialog-description"
                        data-testid="dialogBody"
                    >
                        {dialogBody}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={cancel}
                        color="primary"
                        data-test="button"
                        data-testid="cancelButton"
                    >
                        <b>{translation.btnCancel}</b>
                    </Button>

                    <Button
                        onClick={handleClose}
                        color="primary"
                        data-testid="closeButton"
                    >
                        <b>{translation.btnRemove}</b>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ModalAppBar;
