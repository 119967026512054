import StyledText from '../../common/charts/StyledText';
import { getCiActivitiesFontSize } from './getCiActivitiesFontSize';
import { gradeColorMap } from '../../../theme/gradeColor';

export const CiActivities = ({
    ciActivities,
    ciActivitiesGrade,
}: {
    ciActivities?: string;
    ciActivitiesGrade?: string;
}) => {
    return (
        <StyledText
            color={gradeColorMap.get(ciActivitiesGrade)}
            primaryText={ciActivities}
            primaryTextFontSize={getCiActivitiesFontSize(ciActivities)}
        />
    );
};
