import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CreateIcon from '@mui/icons-material/Create';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';
import theme from '../../theme/theme';

export default function NavBar({
    projectName,
    projectId,
}: {
    grade?: string;
    projectName?: string;
    projectId: string;
}) {
    return (
        <Container
            maxWidth={false}
            disableGutters
            sx={{
                padding: '0 3%',
                backgroundColor: theme.palette.common.white,
            }}
        >
            <Stack direction="row" justifyContent="space-between">
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <IconButton
                        href={'/Projects'}
                        title={'back to projects page'}
                        data-testid="backToProjectsButton"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h1" component="h1" mb="0" ml="10px">
                        {projectName}
                    </Typography>
                </Stack>
                <ReadOnlyTooltip
                    childDisabled={!tokenService.hasEditingRights()}
                >
                    <Button
                        startIcon={<CreateIcon />}
                        size="small"
                        color="primary"
                        href={`/Project/${projectId}`}
                        disabled={!tokenService.hasEditingRights()}
                        data-testid="editProjectButton"
                    >
                        Edit project
                    </Button>
                </ReadOnlyTooltip>
            </Stack>
        </Container>
    );
}
