import { Metric } from '../../api/sentinel';

const branchingStrategyMap = new Map([
    ['NONE', 'None'],
    ['MAIN_DEV', 'Main Dev'],
    ['GITFLOW', 'GitFlow'],
    ['FEATURE', 'Feature'],
    ['FEATURE_BRANCH_PER_RELEASE', 'Feature, Branch Per Release'],
]);

const teamMoraleMap = new Map([
    ['HAPPY', 'Happy'],
    ['SATISFIED', 'Satisfied'],
    ['INDIFFERENT', 'Indifferent'],
    ['DISSATISFIED', 'Dissatisfied'],
    ['UNHAPPY', 'Unhappy'],
]);

const ciActivityMap = new Map([
    [
        'COMPILES_STATIC_ANALYSIS_RUNS_TESTS_GENERATES_ARTIFACT',
        'Compiles, static analysis, runs tests, generates artifact',
    ],
    [
        'COMPILES_STATIC_ANALYSIS_RUNS_TESTS',
        'Compiles, static analysis, runs tests',
    ],
    ['COMPILES_STATIC_ANALYSIS', 'Compiles, static analysis'],
    ['COMPILES', 'Compiles'],
    ['NONE', 'None'],
]);

const metricMapper = (
    metrics?: Metric & {
        branchingStrategy?: string;
        teamMorale?: string;
        ciActivity?: string;
    },
) => {
    if (metrics?.branchingStrategy != null) {
        metrics.branchingStrategy = branchingStrategyMap.get(
            metrics?.branchingStrategy,
        );
    }
    if (metrics?.teamMorale != null) {
        metrics.teamMorale = teamMoraleMap.get(metrics?.teamMorale);
    }
    if (metrics?.ciActivity != null) {
        metrics.ciActivity = ciActivityMap.get(metrics?.ciActivity);
    }
    return metrics;
};
export default metricMapper;
