import { QueryClient, QueryClientProvider } from 'react-query';
import React from 'react';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
        },
    },
});

const QueryProvider = ({ children }: { children: React.ReactNode }) => {
    return (
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    );
};

export default QueryProvider;
