import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineDot from '@mui/lab/TimelineDot';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import TimelineConnector from '@mui/lab/TimelineConnector';
import Box from '@mui/material/Box';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import { useTranslation } from 'react-i18next';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import ScienceIcon from '@mui/icons-material/Science';
import VisibilityIcon from '@mui/icons-material/Visibility';
import FactoryIcon from '@mui/icons-material/Factory';

const DeploymentTargetItem = ({
    value,
    color,
}: {
    value?: string;
    color?: string;
}) => {
    const { t: translate } = useTranslation();
    const getIcon = () => {
        switch (value) {
            case 'NONE':
                return <NotInterestedIcon />;
            case 'CI':
                return <AllInclusiveIcon />;
            case 'TEST':
                return <ScienceIcon />;
            case 'UAT':
                return <VisibilityIcon />;
            case 'PROD_STAGING':
                return <FactoryIcon />;
        }
    };
    const lowerCasedValue = value?.toLowerCase();
    return (
        <TimelineItem
            id={`sentinel-TimelineItem-deploymentTarget-${lowerCasedValue}`}
        >
            <TimelineSeparator>
                <TimelineDot
                    id={`sentinel-TimelineDot-deploymentTarget-${lowerCasedValue}`}
                    sx={{
                        backgroundColor: color,
                    }}
                >
                    {getIcon()}
                </TimelineDot>
                {value != 'PROD_STAGING' && <TimelineConnector />}
            </TimelineSeparator>
            <Box sx={{ width: '80px' }}>
                <TimelineContent
                    sx={{
                        color: color,
                        mt: 2,
                    }}
                >
                    {translate(`deploymentTarget_${lowerCasedValue}_value`)}
                </TimelineContent>
            </Box>
        </TimelineItem>
    );
};

export default DeploymentTargetItem;
