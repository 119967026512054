import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import theme from '../../../theme/theme';

const WidgetTitle = ({
    title,
    subtitle,
    name,
}: {
    title?: string | null;
    subtitle?: string | null;
    name: string;
}) => {
    return (
        <Box>
            {title && (
                <Typography
                    variant="h5"
                    component="h3"
                    mb={theme.spacing(1)}
                    sx={{ textTransform: 'uppercase' }}
                    data-testid={'sentinel-widget-title-' + name}
                    id={'sentinel-widget-title-' + name}
                >
                    {title}
                </Typography>
            )}
            {subtitle && (
                <Typography
                    component="p"
                    variant="caption"
                    data-testid={'sentinel-widget-subtitle-' + name}
                    id={'sentinel-widget-subtitle-' + name}
                >
                    {subtitle}
                </Typography>
            )}
        </Box>
    );
};

export default WidgetTitle;
