import Tooltip from '@mui/material/Tooltip';
import { ReactNode } from 'react';

function ReadOnlyTooltip(props: {
    childDisabled: boolean;
    children: ReactNode;
}) {
    return (
        <>
            {props.childDisabled && (
                <Tooltip
                    title={
                        'You are a read only user. If you want to make updates please send a request to : SentinelFeedback@ALLEGISCLOUD.onmicrosoft.com'
                    }
                    placement={'left-start'}
                >
                    <span>{props.children}</span>
                </Tooltip>
            )}
            {!props.childDisabled && props.children}
        </>
    );
}

export default ReadOnlyTooltip;
