import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import gradeColor from '../theme/gradeColor';
import { useTranslation } from 'react-i18next';

const TrendIcon = ({ trend }: { trend: string | undefined }) => {
    const { t: translate } = useTranslation();
    switch (trend?.toLowerCase()) {
        case 'up':
            return (
                <TrendingUpIcon
                    aria-label={translate('trending_icon_up')}
                    sx={{ color: gradeColor.A }}
                    fontSize="small"
                />
            );
        case 'down':
            return (
                <TrendingDownIcon
                    aria-label={translate('trending_icon_down')}
                    sx={{ color: gradeColor.F }}
                    fontSize="small"
                />
            );

        case 'stable':
            return (
                <TrendingFlatIcon
                    aria-label={translate('trending_icon_flat')}
                    sx={{ color: gradeColor.C }}
                    fontSize="small"
                />
            );

        default:
            return;
    }
};

export default TrendIcon;
