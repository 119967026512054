import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Box, InputLabel } from '@mui/material';
import theme from '../../theme/theme';
import OverflowTooltip from '../../common/OverflowTooltip';

const SprintGoal = ({
    message,
    status,
}: {
    message?: string;
    status?: 'Y' | 'N';
}) => {
    const getLabel = () => {
        const isGoalMet = status === 'Y';
        const isGoalNotMet = status === 'N' && message;

        const statusText = isGoalMet
            ? 'MET'
            : isGoalNotMet
              ? 'NOT MET'
              : 'NOT SET';
        const suffixStyle = {
            color: isGoalNotMet
                ? theme.intensity.orange
                : isGoalMet
                  ? theme.intensity.green
                  : undefined,
        };

        return (
            <>
                SPRINT GOAL (<b style={suffixStyle}>{statusText}</b>)
            </>
        );
    };

    return (
        <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                >
                    <InputLabel shrink>{getLabel()}</InputLabel>
                </Stack>
                <OverflowTooltip title={message}>
                    <Typography variant="body1">{message}</Typography>
                </OverflowTooltip>
            </Box>
        </Stack>
    );
};
export default SprintGoal;
