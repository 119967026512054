// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Sprint } from '../models/index';
import { SprintFromJSON, SprintToJSON } from '../models/index';

export interface AddRequest {
    projectId: string;
    sprint: Sprint;
}

export interface FindSprintsRequest {
    projectId: string;
}

export interface UpdateRequest {
    projectId: string;
    sprintId: number;
    sprint: Sprint;
}

/**
 *
 */
export class SprintApi extends runtime.BaseAPI {
    /**
     */
    async addRaw(
        requestParameters: AddRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Sprint>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling add.',
            );
        }

        if (
            requestParameters.sprint === null ||
            requestParameters.sprint === undefined
        ) {
            throw new runtime.RequiredError(
                'sprint',
                'Required parameter requestParameters.sprint was null or undefined when calling add.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: SprintToJSON(requestParameters.sprint),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            SprintFromJSON(jsonValue),
        );
    }

    /**
     */
    async add(
        requestParameters: AddRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Sprint> {
        const response = await this.addRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findSprintsRaw(
        requestParameters: FindSprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Array<Sprint>>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling findSprints.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints`.replace(
                    `{${'projectId'}}`,
                    encodeURIComponent(String(requestParameters.projectId)),
                ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            jsonValue.map(SprintFromJSON),
        );
    }

    /**
     */
    async findSprints(
        requestParameters: FindSprintsRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Array<Sprint>> {
        const response = await this.findSprintsRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }

    /**
     */
    async updateRaw(
        requestParameters: UpdateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<Sprint>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling update.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling update.',
            );
        }

        if (
            requestParameters.sprint === null ||
            requestParameters.sprint === undefined
        ) {
            throw new runtime.RequiredError(
                'sprint',
                'Required parameter requestParameters.sprint was null or undefined when calling update.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'PUT',
                headers: headerParameters,
                query: queryParameters,
                body: SprintToJSON(requestParameters.sprint),
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            SprintFromJSON(jsonValue),
        );
    }

    /**
     */
    async update(
        requestParameters: UpdateRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<Sprint> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
