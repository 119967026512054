import WidgetHeader from '../common/widget/WidgetHeader';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetItem from '../common/widget/WidgetItem';
import { useTranslation } from 'react-i18next';
import ReleaseHealth from './charts/ReleaseHealth';
import FrequencyOfAchievingGoal from './charts/FrequencyOfAchievingGoal';
import { Metric } from '../../api/sentinel';
import LastRelease from './LastRelease';

const Value = ({
    valueMetric,
}: {
    valueMetric?: Metric & {
        valueTrend?: string;
        freqOfAchievingGoal?: number;
        freqOfAchievingGoalGrade?: string;
        lastRelease?: number;
        lastReleaseGrade?: string;
        lastProductionRelease?: number;
        lastProductionReleaseGrade?: string;
        releaseHealth?: number;
        releaseHealthGrade?: string;
    };
}) => {
    const { t: translate } = useTranslation();

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={'"H H"\n"A B"\n"C C"\n'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={valueMetric?.valueTrend}
                title={translate('value_title')}
                subtitle={translate('value_subtitle')}
            />
            <WidgetItem
                gridArea={'A'}
                title={translate('value_frequency_title')}
                subtitle={translate('value_frequency_subtitle')}
                name="frequency"
            >
                <FrequencyOfAchievingGoal
                    frequencyOfAchievingGoal={valueMetric?.freqOfAchievingGoal}
                    freqOfAchievingGoalGrade={
                        valueMetric?.freqOfAchievingGoalGrade
                    }
                />
            </WidgetItem>

            <WidgetItem
                gridArea={'B'}
                title={translate('value_releaseHealth_title')}
                subtitle={translate('value_releaseHealth_subtitle')}
                name="releaseHealth"
            >
                <ReleaseHealth
                    releaseHealth={valueMetric?.releaseHealth}
                    releaseHealthGrade={valueMetric?.releaseHealthGrade}
                />
            </WidgetItem>

            <WidgetItem
                gridArea={'C'}
                title={translate('value_lastRelease_title')}
                subtitle={translate('value_lastRelease_subtitle')}
                name="lastRelease"
            >
                <LastRelease
                    lastRelease={valueMetric?.lastRelease}
                    lastReleaseGrade={valueMetric?.lastReleaseGrade}
                    lastProductionRelease={valueMetric?.lastProductionRelease}
                    lastProductionReleaseGrade={
                        valueMetric?.lastProductionReleaseGrade
                    }
                />
            </WidgetItem>
        </WidgetContainer>
    );
};
export default Value;
