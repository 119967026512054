import { ToastContainer } from 'react-toastify';
import TopBar from './TopBar';
import { Outlet } from 'react-router-dom';
import Footer from './Footer';

export function Layout() {
    return (
        <div>
            <ToastContainer />
            <TopBar />
            <main style={{ marginBottom: '2rem' }}>
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}
