// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface BurnupChart
 */
export interface BurnupChart {
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    sprintNo?: number;
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    totalBacklog?: number;
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    cumulativeVelocityDelivered?: number;
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    forecastTrend?: number;
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    optimisticTrend?: number;
    /**
     *
     * @type {number}
     * @memberof BurnupChart
     */
    pessimisticTrend?: number;
}

/**
 * Check if a given object implements the BurnupChart interface.
 */
export function instanceOfBurnupChart(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BurnupChartFromJSON(json: any): BurnupChart {
    return BurnupChartFromJSONTyped(json, false);
}

export function BurnupChartFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): BurnupChart {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        sprintNo: !exists(json, 'sprintNo') ? undefined : json['sprintNo'],
        totalBacklog: !exists(json, 'totalBacklog')
            ? undefined
            : json['totalBacklog'],
        cumulativeVelocityDelivered: !exists(
            json,
            'cumulativeVelocityDelivered',
        )
            ? undefined
            : json['cumulativeVelocityDelivered'],
        forecastTrend: !exists(json, 'forecastTrend')
            ? undefined
            : json['forecastTrend'],
        optimisticTrend: !exists(json, 'optimisticTrend')
            ? undefined
            : json['optimisticTrend'],
        pessimisticTrend: !exists(json, 'pessimisticTrend')
            ? undefined
            : json['pessimisticTrend'],
    };
}

export function BurnupChartToJSON(value?: BurnupChart | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        sprintNo: value.sprintNo,
        totalBacklog: value.totalBacklog,
        cumulativeVelocityDelivered: value.cumulativeVelocityDelivered,
        forecastTrend: value.forecastTrend,
        optimisticTrend: value.optimisticTrend,
        pessimisticTrend: value.pessimisticTrend,
    };
}
