import parseISO from 'date-fns/parseISO';
import { format } from 'date-fns';

let locale = 'en-US';

const dateService = {
    setLocale: (newLocale: string) => {
        locale = newLocale;
    },
    toMediumFormat: (d: Date) => {
        return new Intl.DateTimeFormat(locale, {
            dateStyle: 'medium',
        }).format(d);
    },
    toLongFormat: (d: Date) => {
        return new Intl.DateTimeFormat(locale, {
            dateStyle: 'long',
        }).format(d);
    },
    toStandardDateFormat: (date: Date) => {
        return format(date, 'yyyy-MM-dd');
    },
    today: () => {
        const d = new Date();
        const pad = (n: number) => {
            return n < 10 ? `0${n}` : n.toString();
        };
        return dateService.parse(
            `${d.getFullYear()}-${pad(d.getMonth() + 1)}-${pad(d.getDate())}`,
        );
    },

    parse: (s: string) => {
        const parsed = parseISO(s);
        return new Date(
            parsed.getFullYear(),
            parsed.getMonth(),
            parsed.getDate(),
            12,
            0,
            0,
        );
    },

    toServer: (d: Date) => {
        d.setUTCHours(12);
        return d.toJSON();
    },
};

export default dateService;
