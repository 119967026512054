import { useCallback } from 'react';

const useFetchNotes = (manifestFilePath: string | undefined) => {
    return useCallback(async (): Promise<string[]> => {
        const releaseNotesFolder: string = '/releaseNotes/';
        const releaseNotesKey: string = 'release_notes';

        const fetchNotesFileNames = async () => {
            if (!manifestFilePath) {
                throw new Error(`Manifest file in config does not exist.`);
            }

            const response = await fetch(manifestFilePath);
            if (!response.ok) {
                throw new Error(`${manifestFilePath} does not exist.`);
            }

            const text = await response.text();
            if (!text) {
                return [];
            }

            const manifest = JSON.parse(text);

            if (Object.hasOwn(manifest, releaseNotesKey)) {
                return manifest[releaseNotesKey];
            } else {
                return [];
            }
        };

        const fetchNotes = async (): Promise<string[]> => {
            const fileNames = await fetchNotesFileNames();
            const notesFilePromise = fileNames.map(async (fileName: string) => {
                const noteFilePath = releaseNotesFolder + fileName;
                const response = await fetch(noteFilePath);
                return response.text();
            });

            return Promise.all(notesFilePromise);
        };

        return fetchNotes();
    }, [manifestFilePath]);
};

export default useFetchNotes;
