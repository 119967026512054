// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Restriction } from './Restriction';
import {
    RestrictionFromJSON,
    RestrictionFromJSONTyped,
    RestrictionToJSON,
} from './Restriction';

/**
 *
 * @export
 * @interface CalculatedMetricSpec
 */
export interface CalculatedMetricSpec {
    /**
     *
     * @type {string}
     * @memberof CalculatedMetricSpec
     */
    type?: string;
    /**
     *
     * @type {string}
     * @memberof CalculatedMetricSpec
     */
    relationship?: string;
    /**
     *
     * @type {Restriction}
     * @memberof CalculatedMetricSpec
     */
    restriction?: Restriction;
}

/**
 * Check if a given object implements the CalculatedMetricSpec interface.
 */
export function instanceOfCalculatedMetricSpec(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalculatedMetricSpecFromJSON(json: any): CalculatedMetricSpec {
    return CalculatedMetricSpecFromJSONTyped(json, false);
}

export function CalculatedMetricSpecFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): CalculatedMetricSpec {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        type: !exists(json, 'type') ? undefined : json['type'],
        relationship: !exists(json, 'relationship')
            ? undefined
            : json['relationship'],
        restriction: !exists(json, 'restriction')
            ? undefined
            : RestrictionFromJSON(json['restriction']),
    };
}

export function CalculatedMetricSpecToJSON(
    value?: CalculatedMetricSpec | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        type: value.type,
        relationship: value.relationship,
        restriction: RestrictionToJSON(value.restriction),
    };
}
