// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Sprint
 */
export interface Sprint {
    /**
     *
     * @type {number}
     * @memberof Sprint
     */
    id?: number;
    /**
     *
     * @type {number}
     * @memberof Sprint
     */
    sprintId?: number;
    /**
     *
     * @type {number}
     * @memberof Sprint
     */
    sprintNo: number;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    projectId?: string;
    /**
     *
     * @type {Date}
     * @memberof Sprint
     */
    sprintStartDate: Date;
    /**
     *
     * @type {Date}
     * @memberof Sprint
     */
    sprintEndDate: Date;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    sprintGoal?: string;
    /**
     *
     * @type {number}
     * @memberof Sprint
     */
    releaseCount?: number;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    sprintStatus?: SprintSprintStatusEnum;
    /**
     *
     * @type {boolean}
     * @memberof Sprint
     */
    allowSprintCompletion?: boolean;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    createdBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Sprint
     */
    createdOn?: Date;
    /**
     *
     * @type {string}
     * @memberof Sprint
     */
    updatedBy?: string;
    /**
     *
     * @type {Date}
     * @memberof Sprint
     */
    updatedOn?: Date;
}

/**
 * @export
 */
export const SprintSprintStatusEnum = {
    Draft: 'DRAFT',
    Completed: 'COMPLETED',
} as const;
export type SprintSprintStatusEnum =
    (typeof SprintSprintStatusEnum)[keyof typeof SprintSprintStatusEnum];

/**
 * Check if a given object implements the Sprint interface.
 */
export function instanceOfSprint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'sprintNo' in value;
    isInstance = isInstance && 'sprintStartDate' in value;
    isInstance = isInstance && 'sprintEndDate' in value;

    return isInstance;
}

export function SprintFromJSON(json: any): Sprint {
    return SprintFromJSONTyped(json, false);
}

export function SprintFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Sprint {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        id: !exists(json, 'id') ? undefined : json['id'],
        sprintId: !exists(json, 'sprintId') ? undefined : json['sprintId'],
        sprintNo: json['sprintNo'],
        name: !exists(json, 'name') ? undefined : json['name'],
        projectId: !exists(json, 'projectId') ? undefined : json['projectId'],
        sprintStartDate: new Date(`${json['sprintStartDate']}T12:00`),
        sprintEndDate: new Date(`${json['sprintEndDate']}T12:00`),
        sprintGoal: !exists(json, 'sprintGoal')
            ? undefined
            : json['sprintGoal'],
        releaseCount: !exists(json, 'releaseCount')
            ? undefined
            : json['releaseCount'],
        sprintStatus: !exists(json, 'sprintStatus')
            ? undefined
            : json['sprintStatus'],
        allowSprintCompletion: !exists(json, 'allowSprintCompletion')
            ? undefined
            : json['allowSprintCompletion'],
        createdBy: !exists(json, 'createdBy') ? undefined : json['createdBy'],
        createdOn: !exists(json, 'createdOn')
            ? undefined
            : new Date(json['createdOn']),
        updatedBy: !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        updatedOn: !exists(json, 'updatedOn')
            ? undefined
            : new Date(json['updatedOn']),
    };
}

export function SprintToJSON(value?: Sprint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        id: value.id,
        sprintId: value.sprintId,
        sprintNo: value.sprintNo,
        name: value.name,
        projectId: value.projectId,
        sprintStartDate: new Date(
            value.sprintStartDate.getTime() -
                value.sprintStartDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .substring(0, 10),
        sprintEndDate: new Date(
            value.sprintEndDate.getTime() -
                value.sprintEndDate.getTimezoneOffset() * 60000,
        )
            .toISOString()
            .substring(0, 10),
        sprintGoal: value.sprintGoal,
        releaseCount: value.releaseCount,
        sprintStatus: value.sprintStatus,
        allowSprintCompletion: value.allowSprintCompletion,
        createdBy: value.createdBy,
        createdOn:
            value.createdOn === undefined
                ? undefined
                : value.createdOn.toISOString(),
        updatedBy: value.updatedBy,
        updatedOn:
            value.updatedOn === undefined
                ? undefined
                : value.updatedOn.toISOString(),
    };
}
