import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import theme from '../../../theme/theme';
import WidgetTitle from './WidgetTitle';

const WidgetItem = ({
    gridArea,
    children,
    title,
    subtitle,
    name,
}: {
    gridArea: string;
    children: React.ReactNode;
    title?: string | null;
    subtitle?: string | null;
    name: string;
}) => {
    return (
        <Box
            sx={{
                gridArea: gridArea,
                bgcolor: theme.palette.common.white,
                padding: theme.spacing(1),
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                height: '100%',
                borderLeft: `solid 1px ${theme.palette.grey['400']}`,
                borderBottom: `solid 1px ${theme.palette.grey['400']}`,
                marginLeft: '-1px',
                marginTop: '1px',
            }}
        >
            <WidgetTitle title={title} subtitle={subtitle} name={name} />
            <Stack justifyContent="center" alignItems="center">
                {children}
            </Stack>
        </Box>
    );
};

export default WidgetItem;
