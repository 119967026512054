import {
    useController,
    UseControllerReturn,
    useFormContext,
} from 'react-hook-form';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import MoraleRadioGroupRating from './MoraleRadioGroupRating';
import Stack from '@mui/material/Stack';
import HelpIconTooltip from './HelpIconTooltip';

const MoraleInput = (props: {
    name: string;
    label: string;
    tooltip_title?: string;
    disabled?: boolean;
}) => {
    const { name, label } = props;
    const controller: UseControllerReturn = useController({
        name: name,
        control: useFormContext().control,
    });
    return (
        <Stack
            alignItems="end"
            direction="row"
            justifyContent="flex-start"
            spacing={0}
        >
            <FormControl
                fullWidth
                error={!!controller.fieldState.error}
                margin="normal"
                variant="standard"
            >
                <FormLabel>{label}</FormLabel>
                <MoraleRadioGroupRating
                    value={controller.field.value}
                    onChange={controller.field.onChange}
                    disabled={props.disabled}
                    name={props.name}
                />
                {!!controller.fieldState.error && (
                    <FormHelperText>
                        {controller.fieldState.error?.message}
                    </FormHelperText>
                )}
            </FormControl>
            <HelpIconTooltip title={props.tooltip_title} id={'moral'} />
        </Stack>
    );
};

export default MoraleInput;
