import theme from './theme/theme';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const RequestAccess = () => {
    const mailtoEditAccessHref = `mailto:SentinelFeedback@ALLEGISCLOUD.onmicrosoft.com?subject=<Request sentinel read or edit access>&body=`;
    return (
        <Container
            maxWidth={'xl'}
            sx={{
                backgroundColor: theme.palette.common.white,
                padding: '2rem 0',
            }}
        >
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
            >
                <Typography
                    variant="h2"
                    color="primary"
                    component={'div'}
                    sx={{ textTransform: 'none' }}
                >
                    <div>You do not have access to Sentinel.</div>
                </Typography>
                <Typography
                    variant="h6"
                    color="primary"
                    component={'div'}
                    sx={{ textTransform: 'none' }}
                >
                    <div>
                        <a
                            href={mailtoEditAccessHref}
                            rel="noreferrer"
                            data-testid="request-edit-footer-link"
                        >
                            Please click here to send an email to request access
                        </a>
                    </div>
                </Typography>
            </Stack>
        </Container>
    );
};

export default RequestAccess;
