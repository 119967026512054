import { Box, Button, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import URLLink from './URLLink';
import theme from '../../theme/theme';
import CloseIcon from '@mui/icons-material/Close';
import useProjectLinksTextField from './useProjectLinksTextField';
import { useEffect, useState } from 'react';
import useProjectLinksValidator from './useProjectLinksValidator';

const ProjectLinksEditor = ({
    onEnable,
    urlToEdit,
    onAdd,
    onEdit,
    onCancel,
}: {
    onEnable: boolean;
    urlToEdit?: URLLink;
    onAdd: (name: string, link: string) => void;
    onEdit: (urlLink: URLLink) => void;
    onCancel: () => void;
}) => {
    const { t } = useTranslation();
    const translation = {
        edit: t('global_edit'),
        add: t('global_add'),
        cancel: t('global_cancel'),
    };

    const { nameValidator, urlValidator } = useProjectLinksValidator();
    const nameTextField = useProjectLinksTextField(urlToEdit?.name, nameValidator);
    const linkTextField = useProjectLinksTextField(urlToEdit?.url, urlValidator);

    const [disableHTMLElement, setDisableHTMLElement] = useState(false);

    useEffect(() => {
        if (onEnable) {
            setDisableHTMLElement(false);
        }
    }, [onEnable]);

    const reset = () => {
        nameTextField.reset();
        linkTextField.reset();
    };

    const fieldsAreInvalid = (): boolean => {
        const nameFieldIsValid = nameTextField.isValid();
        const linkFieldIsValid = linkTextField.isValid();
        return !nameFieldIsValid || !linkFieldIsValid;
    };

    const handleAdd = () => {
        if (fieldsAreInvalid()) {
            return;
        }

        setDisableHTMLElement(true);
        onAdd(nameTextField.value.trim(), linkTextField.value.trim());
        reset();
    };

    const handleEdit = () => {
        if (fieldsAreInvalid()) {
            return;
        }

        setDisableHTMLElement(true);
        if (urlToEdit) {
            onEdit({
                linkId: urlToEdit.linkId,
                name: nameTextField.value.trim(),
                url: linkTextField.value.trim(),
                createdBy: urlToEdit.createdBy,
                createdOn: urlToEdit.createdOn,
            });
        }
        reset();
    };

    const handleCancel = () => {
        setDisableHTMLElement(true);
        reset();
        onCancel();
    };

    return (
        <Box data-testid="useful-links-editor">
            <Stack spacing={3} paddingTop={'1em'}>
                <TextField
                    data-testid={'url-name'}
                    id={'url-name'}
                    variant={'standard'}
                    label={'Name *'}
                    placeholder={'ex: TEKsystems'}
                    onChange={nameTextField.handleOnChange}
                    value={nameTextField.value}
                    error={!!nameTextField.errorMessage}
                    onBlur={nameTextField.isValid}
                    helperText={nameTextField.errorMessage ?? ''}
                    disabled={disableHTMLElement}
                    InputLabelProps={{ shrink: true }}
                />
                <TextField
                    data-testid={'url-link'}
                    id={'url-link'}
                    variant={'standard'}
                    label={'Link *'}
                    placeholder={'ex: https://www.teksystems.com/en-ca/'}
                    value={linkTextField.value}
                    error={!!linkTextField.errorMessage}
                    onChange={linkTextField.handleOnChange}
                    onBlur={linkTextField.isValid}
                    helperText={linkTextField.errorMessage ?? ''}
                    disabled={disableHTMLElement}
                    InputLabelProps={{ shrink: true }}
                />

                <Stack flexDirection={'row-reverse'}>
                    {urlToEdit ? (
                        <Button
                            data-testid={'button-edit'}
                            variant={'contained'}
                            sx={theme.typography.button}
                            onClick={handleEdit}
                            disabled={disableHTMLElement}
                        >
                            {translation.edit}
                        </Button>
                    ) : (
                        <Button
                            data-testid={'button-add'}
                            variant={'contained'}
                            onClick={handleAdd}
                            disabled={disableHTMLElement}
                        >
                            {translation.add}
                        </Button>
                    )}
                    <Button
                        data-testid={'button-cancel'}
                        startIcon={<CloseIcon />}
                        onClick={handleCancel}
                        disabled={disableHTMLElement}
                    >
                        {translation.cancel}
                    </Button>
                </Stack>
            </Stack>
        </Box>
    );
};

export default ProjectLinksEditor;
