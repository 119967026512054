import React, { useRef, useState, useEffect, ReactElement } from 'react';
import { Tooltip } from '@mui/material';

interface OverflowTooltipProps {
    children: ReactElement;
    title?: string;
}

const OverflowTooltip: React.FC<OverflowTooltipProps> = ({
    children,
    title,
}) => {
    const textRef = useRef<HTMLDivElement>(null);
    const [isOverflow, setIsOverflow] = useState(false);

    useEffect(() => {
        const checkOverflow = () => {
            if (textRef.current) {
                setIsOverflow(
                    textRef.current.scrollWidth > textRef.current.clientWidth,
                );
            }
        };
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return () => window.removeEventListener('resize', checkOverflow);
    }, []);

    return (
        <Tooltip title={title} disableHoverListener={!isOverflow}>
            {React.cloneElement(children, {
                ref: textRef,
                sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    paddingBottom: '0.2em',
                    textOverflow: 'ellipsis',
                    ...children.props.sx,
                },
            })}
        </Tooltip>
    );
};

export default OverflowTooltip;
