import { Container, Dialog } from '@mui/material';
import ModalAppBar from '../common/ModalAppBar';
import theme from '../theme/theme';
import ProjectForm from './components/ProjectForm';
import useProjectMode from './useProjectMode';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectFormModel } from './services/projectFormModel';
import useProjectData from './useProjectData';
import Spinner from '../common/Spinner';
import useResultToasts from './useResultToasts';

const Project = () => {
    const { projectId } = useParams();
    const { returnPath, toolbarProps } = useProjectMode(projectId);
    const { formModel, isLoading, isError, error, save } =
        useProjectData(projectId);
    const { successToast, failureToast } = useResultToasts();
    const [isFormDirty, setIsFormDirty] = useState(false);
    const navigate = useNavigate();

    if (isLoading) {
        return <Spinner open={true} />;
    }

    if (isError) {
        return <>Error: {error}</>;
    }

    const saveProject = async (model: ProjectFormModel) => {
        try {
            await save(model);
            successToast();
            returnToDashboard();
        } catch (e) {
            failureToast(e);
        }
    };

    const returnToDashboard = () => {
        navigate(returnPath);
    };

    return (
        <Dialog fullScreen open={true}>
            <ModalAppBar
                showWarnings={isFormDirty}
                title={toolbarProps.title}
                dialogTitle={toolbarProps.dialogTitle}
                dialogBody={toolbarProps.dialogBody}
                onClose={returnToDashboard}
            />
            <Container
                maxWidth={false}
                sx={{
                    backgroundColor: theme.palette.grey['100'],
                    height: '100%',
                }}
            >
                <Container
                    maxWidth="md"
                    sx={{
                        backgroundColor: theme.palette.common.white,
                        paddingBottom: '2rem',
                        paddingTop: '2rem',
                    }}
                >
                    <ProjectForm
                        formModel={formModel}
                        onSave={saveProject}
                        formDirtyStatusCallback={setIsFormDirty}
                    />
                </Container>
            </Container>
        </Dialog>
    );
};

export default Project;
