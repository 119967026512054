// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface ValueConstraint
 */
export interface ValueConstraint {
    /**
     *
     * @type {number}
     * @memberof ValueConstraint
     */
    min?: number;
    /**
     *
     * @type {number}
     * @memberof ValueConstraint
     */
    max?: number;
    /**
     *
     * @type {boolean}
     * @memberof ValueConstraint
     */
    required?: boolean;
}

/**
 * Check if a given object implements the ValueConstraint interface.
 */
export function instanceOfValueConstraint(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ValueConstraintFromJSON(json: any): ValueConstraint {
    return ValueConstraintFromJSONTyped(json, false);
}

export function ValueConstraintFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): ValueConstraint {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        min: !exists(json, 'min') ? undefined : json['min'],
        max: !exists(json, 'max') ? undefined : json['max'],
        required: !exists(json, 'required') ? undefined : json['required'],
    };
}

export function ValueConstraintToJSON(value?: ValueConstraint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        min: value.min,
        max: value.max,
        required: value.required,
    };
}
