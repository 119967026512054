import * as React from 'react';
import { styled } from '@mui/material/styles';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const StyledRating = styled(Rating)(({theme}) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },
}));

const customIcons: {
    [index: string]: {
        icon: React.ReactElement;
        label: string;
    };
} = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error"/>,
        label: 'UNHAPPY',
    },
    2: {
        icon: <SentimentDissatisfiedIcon color="error"/>,
        label: 'DISSATISFIED',
    },
    3: {
        icon: <SentimentSatisfiedIcon color="warning"/>,
        label: 'INDIFFERENT',
    },
    4: {
        icon: <SentimentSatisfiedAltIcon color="success"/>,
        label: 'SATISFIED',
    },
    5: {
        icon: <SentimentVerySatisfiedIcon color="success"/>,
        label: 'HAPPY',
    },
};

function IconContainer(props: IconContainerProps) {
    const {value, ...other} = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const MoraleRadioGroupRating = ({
                                    value,
                                    onChange,
                                    disabled,
                                    name,
                                }: {
    value: number | null;
    onChange: (value: number | null) => void;
    disabled: boolean | undefined;
    name: string;
}) => {
    return (
        <StyledRating
            readOnly={disabled}
            name={'moral-ratting-' + name}
            value={value}
            IconContainerComponent={IconContainer}
            getLabelText={(value: number) => customIcons[value]?.label || ''}
            highlightSelectedOnly
            onChange={(_, value) => {
                onChange(value);
            }}
            id={'sentinel-moral-ratting-field-' + name}
            data-testid={'sentinel-moral-ratting-field-' + name}
        />
    );
};

export default MoraleRadioGroupRating;
