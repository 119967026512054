import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { gradeColorMap } from '../../theme/gradeColor';

const OverallScoreCell = ({
    value: overallScore,
    row,
    id,
}: GridRenderCellParams) => {
    const { t } = useTranslation();
    const translation = {
        notApplicable: t('global_na'),
    };
    const { intensity } = useTheme();
    const isProjectActive = row.active;

    return isProjectActive ? (
        <Box
            id={`overallScoreCell_${id}`}
            alignItems={'center'}
            justifyContent={'center'}
            display={'flex'}
            sx={{
                color: intensity.white,
                backgroundColor: gradeColorMap.get(row['overallScoreGrade']),
                borderRadius: '2rem',
                height: '40px',
                width: '40px',
            }}
        >
            {overallScore}
        </Box>
    ) : (
        <Box>{translation.notApplicable}</Box>
    );
};

export default OverallScoreCell;
