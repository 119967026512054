import NoData from '../../../common/NoData';
import { PieChart, useDrawingArea } from '@mui/x-charts';
import { gradeColorMap } from '../../../theme/gradeColor';
import theme from '../../../theme/theme';
import { styled } from '@mui/material/styles';

const Donut = ({
    metric,
}: {
    metric?: {
        value?: number;
        grade?: string;
    };
}) => {
    const data = [
        {
            value: metric?.value ?? 0,
            color: gradeColorMap.get(metric?.grade),
        },
        {
            value: 100 - (metric?.value ?? 0),
            color: theme.palette.grey['500'],
        },
    ];

    const StyledText = styled('text')(() => ({
        fill: gradeColorMap.get(metric?.grade),
        fontWeight: 500,
        textAnchor: 'middle',
        dominantBaseline: 'central',
        fontSize: 'min(1.5rem, 3vw)',
    }));

    function PieCenterLabel({ children }: { children: React.ReactNode }) {
        const { width, height, left, top } = useDrawingArea();
        return (
            <StyledText x={left + width / 2} y={top + height / 2}>
                {children}
            </StyledText>
        );
    }

    const isEmptyOrUndefined = !metric?.value && metric?.value !== 0;
    return isEmptyOrUndefined ? (
        <NoData />
    ) : (
        <PieChart
            height={250}
            series={[
                {
                    data: data,
                    innerRadius: 70,
                    outerRadius: 90,
                    paddingAngle: 5,
                    cornerRadius: 5,
                },
            ]}
            margin={{ left: 0, top: 0, right: 0 }}
            tooltip={{ trigger: 'none' }}
        >
            <PieCenterLabel>{metric.value}%</PieCenterLabel>
        </PieChart>
    );
};

export default Donut;
