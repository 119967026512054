import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { gradeColorMap } from '../../../theme/gradeColor';
import { Typography } from '@mui/material';
import NoData from '../../../common/NoData';

const Morale = ({ value, grade }: { value?: string; grade?: string }) => {
    const size = '7rem';
    const textSize = '1.5rem';

    const getMoraleIcon = () => {
        const commonProps = {
            id: `sentinel-moral-icon-${value?.toLowerCase()}`,
            style: {
                fontSize: size,
                color: gradeColorMap.get(grade),
            },
        };
        switch (value) {
            case 'Happy':
                return <SentimentSatisfiedAltIcon {...commonProps} />;
            case 'Satisfied':
                return <SentimentSatisfiedIcon {...commonProps} />;
            case 'Indifferent':
                return <SentimentNeutralIcon {...commonProps} />;
            case 'Dissatisfied':
                return <SentimentDissatisfiedIcon {...commonProps} />;
            case 'Unhappy':
                return <SentimentVeryDissatisfiedIcon {...commonProps} />;
        }
    };

    return !value || !grade ? (
        <NoData />
    ) : (
        <div style={{ textAlign: 'center' }}>
            {getMoraleIcon()}
            <Typography
                style={{
                    fontSize: textSize,
                    fontWeight: 500,
                }}
            >
                {value}
            </Typography>
        </div>
    );
};

export default Morale;
