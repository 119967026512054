// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { Metric } from '../models/index';
import { MetricFromJSON, MetricToJSON } from '../models/index';

export interface GetCalculatedMetricsForSprintRequest {
    projectId: string;
    sprintId: number;
}

/**
 *
 */
export class CalculatedMetricsApi extends runtime.BaseAPI {
    /**
     * Get all the calculated metrics for the specified sprint
     */
    async getCalculatedMetricsForSprintRaw(
        requestParameters: GetCalculatedMetricsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<{ [key: string]: Metric }>> {
        if (
            requestParameters.projectId === null ||
            requestParameters.projectId === undefined
        ) {
            throw new runtime.RequiredError(
                'projectId',
                'Required parameter requestParameters.projectId was null or undefined when calling getCalculatedMetricsForSprint.',
            );
        }

        if (
            requestParameters.sprintId === null ||
            requestParameters.sprintId === undefined
        ) {
            throw new runtime.RequiredError(
                'sprintId',
                'Required parameter requestParameters.sprintId was null or undefined when calling getCalculatedMetricsForSprint.',
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/projects/{projectId}/sprints/{sprintId}/metrics/calculated`
                    .replace(
                        `{${'projectId'}}`,
                        encodeURIComponent(String(requestParameters.projectId)),
                    )
                    .replace(
                        `{${'sprintId'}}`,
                        encodeURIComponent(String(requestParameters.sprintId)),
                    ),
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            runtime.mapValues(jsonValue, MetricFromJSON),
        );
    }

    /**
     * Get all the calculated metrics for the specified sprint
     */
    async getCalculatedMetricsForSprint(
        requestParameters: GetCalculatedMetricsForSprintRequest,
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<{ [key: string]: Metric }> {
        const response = await this.getCalculatedMetricsForSprintRaw(
            requestParameters,
            initOverrides,
        );
        return await response.value();
    }
}
