import GaugeChart from '../../common/charts/GaugeChart';

const ReleaseHealth = ({
    releaseHealth,
    releaseHealthGrade,
}: {
    releaseHealth?: number;
    releaseHealthGrade?: string;
}) => {
    return (
        <GaugeChart
            value={releaseHealth}
            grade={releaseHealthGrade}
            deltas={{
                f: 80,
                d: 10,
                c: 5,
                b: 4,
                a: 1,
            }}
            cy="70%"
            height={250}
            gaugeChartName="releaseHealth"
        />
    );
};

export default ReleaseHealth;
