import {
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import NoData from '../../../common/NoData';
import { useQuery } from 'react-query';
import burnupService from './BurnupService';
import CircularProgress from '@mui/material/CircularProgress';

const Burnup = ({
    projectId,
    sprintId,
}: {
    projectId: string;
    sprintId?: number;
}) => {
    const { t: translate } = useTranslation();

    const {
        isLoading,
        error,
        data: burnUpData,
    } = useQuery(
        `productivity-burnupChart-${projectId}-${sprintId}`,
        () => {
            return burnupService.findBurnupChartData(projectId, sprintId);
        },
        {
            cacheTime: 0,
            staleTime: 0,
        },
    );

    if (isLoading) {
        return <CircularProgress color="primary" />;
    }

    if (error) {
        return <p>productivity burnup data in error </p>;
    }

    if (!burnUpData?.burnupChart) {
        return <NoData />;
    } else {
        return (
            <ResponsiveContainer
                width="99%"
                height={255}
                id={'sentinel-burnup-container-burnupChart'}
            >
                <LineChart
                    id={'sentinel-burnup-lineChart-burnupChart'}
                    cx="50%"
                    cy="50%"
                    data={burnUpData.burnupChart}
                    margin={{
                        top: 30,
                        right: 60,
                        left: 60,
                        bottom: 30,
                    }}
                >
                    <XAxis
                        id={'sentinel-burnup-XAxis-burnupChart'}
                        data-testid={'sentinel-burnup-XAxis-burnupChart'}
                        dataKey="sprintNo"
                        label={{
                            value: translate('burnup_sprint'),
                            position: 'bottom',
                            fill: '#5B5A5A',
                            style: {
                                textAnchor: 'middle',
                                fontSize: '0.875rem',
                            },
                            offset: 5,
                        }}
                        height={30}
                        tick={{
                            fontSize: '14px',
                        }}
                    />
                    <YAxis
                        id={'sentinel-burnup-YAxis-burnupChart'}
                        data-testid={'sentinel-burnup-YAxis-burnupChart'}
                        label={{
                            value: translate('burnup_storyPoint'),
                            angle: -90,
                            position: 'left',
                            fill: '#5B5A5A',
                            offset: 30,
                            style: {
                                textAnchor: 'middle',
                                fontSize: '0.875rem',
                            },
                        }}
                        width={25}
                        tick={{
                            fontSize: '14px',
                        }}
                    />
                    <Tooltip />
                    <Line
                        id={'sentinel-burnup-line-totalBacklog-burnupChart'}
                        data-testid={
                            'sentinel-burnup-line-totalBacklog-burnupChart'
                        }
                        name={translate('burnup_totalBacklog_tooltip')}
                        type="linear"
                        dataKey="totalBacklog"
                        stroke="#002C56"
                        strokeWidth={2}
                    />
                    <Line
                        id={
                            'sentinel-burnup-line-cumulVelocityDelivered-burnupChart'
                        }
                        data-testid={
                            'sentinel-burnup-line-cumulVelocityDelivered-burnupChart'
                        }
                        name={translate(
                            'burnup_cumulVelocityDelivered_tooltip',
                        )}
                        type="linear"
                        dataKey="cumulativeVelocityDelivered"
                        stroke="#007698"
                        strokeWidth={2}
                    />
                    <Line
                        id={'sentinel-burnup-line-forecastTrend-burnupChart'}
                        data-testid={
                            'sentinel-burnup-line-forecastTrend-burnupChart'
                        }
                        name={translate('burnup_forecastTrend_tooltip')}
                        type="linear"
                        dataKey="forecastTrend"
                        stroke="#0095D3"
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        id={'sentinel-burnup-line-optimisticTrend-burnupChart'}
                        data-testid={
                            'sentinel-burnup-line-optimisticTrend-burnupChart'
                        }
                        name={translate('burnup_optimisticTrend_tooltip')}
                        type="linear"
                        dataKey="optimisticTrend"
                        stroke="#8DC63F"
                        strokeWidth={2}
                        dot={false}
                    />
                    <Line
                        id={'sentinel-burnup-line-pessimisticTrend-burnupChart'}
                        data-testid={
                            'sentinel-burnup-line-pessimisticTrend-burnupChart'
                        }
                        name={translate('burnup_pessimisticTrend_tooltip')}
                        type="linear"
                        dataKey="pessimisticTrend"
                        stroke="#DF7D1C"
                        strokeWidth={2}
                        dot={false}
                    />
                </LineChart>
            </ResponsiveContainer>
        );
    }
};

export default Burnup;
