// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from '../runtime';
import type { ProvidedMetricSpec } from '../models/index';
import {
    ProvidedMetricSpecFromJSON,
    ProvidedMetricSpecToJSON,
} from '../models/index';

/**
 *
 */
export class ProvidedMetricSpecsApi extends runtime.BaseAPI {
    /**
     * Get all the specification data for the provided metrics
     */
    async getProvidedMetricSpecsRaw(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<runtime.ApiResponse<{ [key: string]: ProvidedMetricSpec }>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token('bearerAuth', []);

            if (tokenString) {
                headerParameters['Authorization'] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request(
            {
                path: `/metrics/providedSpecs`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            },
            initOverrides,
        );

        return new runtime.JSONApiResponse(response, jsonValue =>
            runtime.mapValues(jsonValue, ProvidedMetricSpecFromJSON),
        );
    }

    /**
     * Get all the specification data for the provided metrics
     */
    async getProvidedMetricSpecs(
        initOverrides?: RequestInit | runtime.InitOverrideFunction,
    ): Promise<{ [key: string]: ProvidedMetricSpec }> {
        const response = await this.getProvidedMetricSpecsRaw(initOverrides);
        return await response.value();
    }
}
