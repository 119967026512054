import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { getPcaConfig } from './authentication/authConfig';
import tokenService from './authentication/tokenService';
import config from './services/config';
import { MockProvider, Providers } from '@microsoft/mgt-element';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

const fakeToken: string | undefined = config.get('REACT_APP_USE_FAKE_TOKEN');

if (fakeToken) {
    tokenService.setToken(fakeToken);
    Providers.globalProvider = new MockProvider(true);

    root.render(<App usingMsal={false} />);
} else {
    PublicClientApplication.createPublicClientApplication(getPcaConfig()).then(
        pcaInstance => {
            root.render(
                <React.StrictMode>
                    <MsalProvider instance={pcaInstance}>
                        <App usingMsal={true} />
                    </MsalProvider>
                </React.StrictMode>,
            );
        },
    );
}
