import Timeline from '@mui/lab/Timeline';
import { gradeColorMap } from '../../../theme/gradeColor';
import DeploymentTargetItem from './DeploymentTargetItem';
import NoData from '../../../common/NoData';

const deploymentOrder: Record<string, number> = {
    NONE: 1,
    CI: 2,
    TEST: 3,
    UAT: 4,
    PROD_STAGING: 5,
};

const DeploymentTarget = ({
    value,
    grade,
}: {
    value?: string;
    grade?: string;
}) => {
    const selectedTarget: number = deploymentOrder[value ?? ''];
    const getColor = (itemTarget: number) => {
        return itemTarget <= selectedTarget
            ? gradeColorMap.get(grade)
            : 'inherits';
    };
    return value ? (
        <Timeline position="left">
            {Object.entries(deploymentOrder).map(([target]) => (
                <DeploymentTargetItem
                    value={target}
                    color={getColor(deploymentOrder[target])}
                    key={target}
                />
            ))}
        </Timeline>
    ) : (
        <NoData />
    );
};

export default DeploymentTarget;
