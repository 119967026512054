export const getCiActivitiesFontSize = (value?: string) => {
    switch (value) {
        case 'Compiles, static analysis, runs tests, generates artifact':
            return '1.375rem';
        case 'Compiles, static analysis, runs tests':
            return '1.5rem';
        case 'Compiles, static analysis':
            return '1.75rem';
        case 'Compiles':
            return '1.875rem';
        default:
            return '2rem';
    }
};
