import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TrendIcon from '../../../common/TrendIcon';
import theme from '../../../theme/theme';

const WidgetHeader = ({
    gridArea,
    trend,
    title,
    subtitle,
}: {
    numericalGrade?: number;
    letterGrade?: string;
    gridArea: string;
    trend: string | undefined;
    title: string;
    subtitle: string;
}) => {
    return (
        <Stack
            sx={{
                backgroundColor: theme.palette.common.white,
                borderBottom: `solid 1px ${theme.palette.grey['400']}`,
                gridArea: gridArea,
                paddingLeft: '10px',
            }}
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0.5}
                >
                    <Typography
                        component="h2"
                        variant="h4"
                        sx={{ textTransform: 'uppercase' }}
                    >
                        {title}
                    </Typography>
                    <TrendIcon trend={trend} />
                </Stack>
                <Typography variant="body2">{subtitle}</Typography>
            </Box>
        </Stack>
    );
};

export default WidgetHeader;
