import { Project } from '../../api/sentinel';
import projectRepository from './projectListRepository';
import { safeParseInt } from '../../utils/util';

export type ProjectListFilter = {
    status?: 'active' | 'inactive' | 'all';
    locationId?: string;
};

const projectService = {
    get: ({ status, locationId }: ProjectListFilter) => {
        return projectRepository.findProjects({
            locationId: safeParseInt(locationId),
            status: status,
        });
    },

    getById: (id: string): Promise<Project> => {
        return projectRepository.getProject({ projectId: id });
    },

    save: (project: Project) => {
        if (project.projectId) {
            return projectRepository.updateProject({
                projectId: project.projectId,
                project: project,
            });
        }
        return projectRepository.saveProject({ project: project });
    },
};

export default projectService;
