import { calculatedMetricsApi, providedMetricsApi } from '../../api/sentinel';

const metricService = {
    getMetric: async (projectId: string, sprintId?: number) => {
        if (sprintId) {
            const providedMetricPromise =
                providedMetricsApi.getProvidedMetricsForSprint({
                    projectId: projectId,
                    sprintId: sprintId,
                });

            const calculatedMetricPromise =
                calculatedMetricsApi.getCalculatedMetricsForSprint({
                    projectId: projectId,
                    sprintId: sprintId,
                });

            const metrics = await Promise.all([
                providedMetricPromise,
                calculatedMetricPromise,
            ]);

            return { ...metrics[0], ...metrics[1] };
        }
        return undefined;
    },
};
export default metricService;
