import * as yup from 'yup';

import { useQuery } from 'react-query';
import { ISchema } from 'yup';
import {
    CalculatedMetricSpec,
    calculatedMetricsSpecsApi,
} from '../api/sentinel';

export type Category = 'QUALITY';

export type NumberMetricSpec = {
    type: 'INTEGER' | 'DECIMAL';
    category: Category;
    resolver?: ISchema<number>;
    restriction?: {
        readAccessRole: string;
    };
};
export type StringMetricSpec = {
    type: 'STRING';
    category: Category;
    resolver?: ISchema<string>;
    restriction?: {
        readAccessRole: string;
    };
};

export type MetricSpec = NumberMetricSpec | StringMetricSpec;

const useCalculatedMetricSpecs = () => {
    const calculatedMetricSpecsData = useQuery<
        Record<string, CalculatedMetricSpec>,
        Error
    >(['calculatedMetricSpecs'], async ({ signal }) => {
        return calculatedMetricsSpecsApi.getCalculatedMetricSpecs({ signal });
    });
    const metricSpecs = calculatedMetricSpecsData.data ?? {};
    const isLoadingSpecs = calculatedMetricSpecsData.isLoading;

    type Type = 'INTEGER' | 'DECIMAL' | 'STRING';

    const resolverMap: Record<
        Type,
        (constraints?: Record<string, number>) => yup.AnySchema
    > = {
        INTEGER: () => yup.number(),
        DECIMAL: () => yup.number(),
        STRING: () => yup.string(),
    };

    const calculatedMetricSpecs: Record<string, MetricSpec> = {};

    if (Object.keys(metricSpecs).length > 0) {
        ['overallScore'].forEach(metricKey => {
            const value = metricSpecs[metricKey];
            if (!value) {
                return;
            }
            const { type, relationship, restriction } = value;
            let resolver: yup.AnySchema = yup.string();

            resolver = resolverMap[type as Type]();

            calculatedMetricSpecs[metricKey] = {
                type,
                resolver,
                category: relationship as Category,
                restriction,
            } as MetricSpec;
        });
    }

    return {
        calculatedMetricSpecs,
        isLoadingSpecs,
    };
};

export default useCalculatedMetricSpecs;
