import { Metric } from '../../api/sentinel';
import { Category, MetricSpec } from '../../services/useProvidedMetricSpecs';
import { SprintFormModel } from './sprintMapper';

function valueToMetric(
    value: number | string | Date | boolean | undefined,
    spec: MetricSpec,
): Metric | undefined {
    switch (spec.type) {
        case 'INTEGER':
        case 'DECIMAL':
            return value as number;
        case 'TEAM_MORALE': {
            const map = new Map([
                [1, 'UNHAPPY'],
                [2, 'DISSATISFIED'],
                [3, 'INDIFFERENT'],
                [4, 'SATISFIED'],
                [5, 'HAPPY'],
            ]);
            return map.get(value as number) || undefined;
        }
        default:
            return value as string || undefined;
    }
}

function valuesToMetrics(
    category: Category,
    data: SprintFormModel,
    specs: Record<string, MetricSpec>,
): Record<string, Metric> {
    return Object.fromEntries(
        Object.entries(specs)
            .filter(([, spec]) => spec.category === category)
            .map(([key, spec]) => [key, valueToMetric(data[key], spec)])
            .filter(([, metric]) => metric !== undefined),
    );
}

export const generateProvidedMetricsRequest = (
    data: SprintFormModel,
    specs: Record<string, MetricSpec>,
) => ({
    ...valuesToMetrics('AGILITY', data, specs),
    ...valuesToMetrics('SOFTWARE_HABITABILITY', data, specs),
    ...valuesToMetrics('QUALITY', data, specs),
    ...valuesToMetrics('ENVIRONMENT', data, specs),
    ...valuesToMetrics('RELEASE', data, specs),
});
