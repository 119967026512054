declare global {
    interface Window {
        injectedEnv: Record<string, string>;
    }
}

export const validKeys = [
    'REACT_APP_AUTH_AUTHORITY',
    'REACT_APP_AUTH_CLIENT_ID',
    'REACT_APP_AUTH_REDIRECT_URI',
    'REACT_APP_AUTH_SCOPES',
    'REACT_APP_SPRING_API_URL',
    'REACT_APP_USE_FAKE_TOKEN',
    'REACT_APP_EDITOR_ROLE_NAME',
    'REACT_APP_READER_ROLE_NAME',
    'REACT_APP_LEADER_ROLE_NAME',
    'REACT_APP_GRAPH_SCOPES',
];

const base: Record<string, string> = {
    REACT_APP_RELEASENAME: 'local',
    REACT_APP_BUILDNUMBER: 'local',
    REACT_API_RELEASENAME: 'local',
    REACT_API_BUILDNUMBER: 'local',

    REACT_APP_EDITOR_ROLE_NAME: 'write',
    REACT_APP_READER_ROLE_NAME: 'view',
    REACT_APP_LEADER_ROLE_NAME: 'leader',

    PATH_RELEASE_NOTES_MANIFEST: 'releaseNotes/manifest.json',
};

function getVars(keys: string[], source: Record<string, string>) {
    return keys.reduce(
        (map, key) => {
            if (source && (source[key] || source[key] === '')) {
                map[key] = source[key];
            }
            return map;
        },
        {} as Record<string, string>,
    );
}

const configMap: Record<string, string | undefined> = {
    ...base,
    ...getVars(validKeys, process.env as Record<string, string>),
    ...getVars(validKeys, window.injectedEnv),
};

export default {
    get(key: string): string | undefined {
        return configMap[key];
    },
};
