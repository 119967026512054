import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LastReleaseDetails from './charts/LastReleaseDetails';
import { useTranslation } from 'react-i18next';

const LastRelease = ({
    lastRelease,
    lastReleaseGrade,
    lastProductionRelease,
    lastProductionReleaseGrade,
}: {
    lastRelease?: number;
    lastReleaseGrade?: string;
    lastProductionRelease?: number;
    lastProductionReleaseGrade?: string;
}) => {
    const { t } = useTranslation();

    const translation = {
        allEnvironment: t('value_lastRelease_allEnvironment'),
        prodEnvironment: t('value_lastRelease_productionEnvironment'),
    };

    return (
        <Grid container>
            <Grid item xs={5}>
                <Typography component="p" variant="caption">
                    {translation.allEnvironment}
                </Typography>
                <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                >
                    <LastReleaseDetails
                        lastRelease={lastRelease}
                        lastReleaseGrade={lastReleaseGrade}
                    />
                </Grid>
            </Grid>
            <Grid item xs={1}>
                <Divider orientation="vertical" />
            </Grid>
            <Grid item xs={5} paddingLeft={'1rem'}>
                <Typography component="p" variant="caption">
                    {translation.prodEnvironment}
                </Typography>
                <Grid
                    container
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'100%'}
                >
                    <LastReleaseDetails
                        lastRelease={lastProductionRelease}
                        lastReleaseGrade={lastProductionReleaseGrade}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default LastRelease;
