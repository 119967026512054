import { burnupChartApi } from '../../../api/sentinel';

const burnupService = {
    findBurnupChartData: async (projectId: string, sprintId?: number) => {
        if (sprintId) {
            return burnupChartApi
                .getBurnupChart({ projectId: projectId, sprintId: sprintId })
                .catch(reason => {
                    console.warn(`error: ${reason}`);
                    return undefined;
                });
        }
        return undefined;
    },
};
export default burnupService;
