import WidgetHeader from '../common/widget/WidgetHeader';
import Burnup from './charts/Burnup';
import ReleaseCycleIcon from './charts/ReleaseCycleIcon';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetItem from '../common/widget/WidgetItem';
import { useTranslation } from 'react-i18next';
import { Metric } from '../../api/sentinel';
import { gradeColorMap } from '../../theme/gradeColor';

const Productivity = ({
    projectId,
    sprintId,
    productivityMetrics,
}: {
    projectId: string;
    sprintId?: number;
    productivityMetrics?: Metric & {
        releaseCycle?: number;
        releaseCycleGrade?: string;
        productivityTrend?: string;
    };
}) => {
    const { t: translate } = useTranslation();

    return (
        <WidgetContainer
            gridTemplateColumns={'1fr'}
            gridTemplateAreas={'"H"\n"A"\n"B"'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={productivityMetrics?.productivityTrend}
                title={translate('productivity_title')}
                subtitle={translate('productivity_subtitle')}
            />

            <WidgetItem
                gridArea={'A'}
                title={translate('productivity_burnupChart_title')}
                subtitle={translate('productivity_burnupChart_subtitle')}
                name="burnupChart"
            >
                <Burnup projectId={projectId} sprintId={sprintId} />
            </WidgetItem>
            <WidgetItem
                gridArea={'B'}
                title={translate('productivity_releaseCycle_title')}
                subtitle={translate('productivity_releaseCycle_subtitle')}
                name="releaseCycle"
            >
                <ReleaseCycleIcon
                    value={productivityMetrics?.releaseCycle}
                    gradeColor={gradeColorMap.get(
                        productivityMetrics?.releaseCycleGrade,
                    )}
                />
            </WidgetItem>
        </WidgetContainer>
    );
};
export default Productivity;
