import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { useNavigate } from 'react-router-dom';
import tokenService from '../../authentication/tokenService';
import ReadOnlyTooltip from '../../common/ReadOnlyTooltip';

export const EditProjectCell = (props: GridRenderCellParams) => {
    const navigate = useNavigate();
    const { row } = props;

    return (
        <ReadOnlyTooltip childDisabled={!tokenService.hasEditingRights()}>
            <IconButton
                aria-label={`Edit Project ${row.id}`}
                tabIndex={props.tabIndex}
                color="primary"
                disabled={!tokenService.hasEditingRights()}
                onClick={e => {
                    e.stopPropagation();
                    navigate(`/Project/${row.projectId}`);
                }}
            >
                <EditIcon />
            </IconButton>
        </ReadOnlyTooltip>
    );
};
