import SvgIcon from '@mui/material/SvgIcon';
import NoData from '../../../common/NoData';
import StyledText from '../../common/charts/StyledText';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ReleaseCycleIcon({
    value,
    gradeColor,
}: {
    value?: number;
    gradeColor?: string;
}) {
    const { t: translate } = useTranslation();
    if (value === undefined) {
        return <NoData />;
    } else {
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <SvgIcon
                    viewBox="0 0 884.3 980"
                    style={{
                        fontSize: '8rem',
                        fill: gradeColor,
                    }}
                >
                    <g transform="translate(-57.9 -10)">
                        <g data-name="Group 76">
                            <g data-name="Group 75">
                                <path
                                    id="Path_1066"
                                    data-name="Path 1066"
                                    d="M942.2,452.1C942.2,208.3,743.8,10,500,10a436.037,436.037,0,0,0-44.2,2.2,14.285,14.285,0,0,0,1.4,28.5,6.6,6.6,0,0,0,1.4-.1A408.446,408.446,0,0,1,500,38.5C728,38.4,913.6,224,913.6,452c0,226.9-183.7,411.6-410.1,413.4l83-83a14.284,14.284,0,0,0-20.2-20.2L454.5,874,566.3,985.8a14.154,14.154,0,0,0,10.1,4.2,14.3,14.3,0,0,0,10.1-24.4l-72.2-72.2C751.5,885.9,942.2,691.1,942.2,452.1Z"
                                />
                                <path
                                    id="Path_1067"
                                    data-name="Path 1067"
                                    d="M179.9,713.9a14.22,14.22,0,1,0-22.1,17.9q13.95,17.1,29.6,32.8a14.284,14.284,0,1,0,20.2-20.2A347.91,347.91,0,0,1,179.9,713.9Z"
                                />
                                <path
                                    id="Path_1068"
                                    data-name="Path 1068"
                                    d="M88.4,492.5c-1.3-13.4-1.9-27-1.9-40.4,0-13.6.6-27.2,1.9-40.6a14.262,14.262,0,0,0-12.8-15.6A14.538,14.538,0,0,0,60,408.7c-1.4,14.3-2.1,28.9-2.1,43.4,0,14.4.7,28.9,2.1,43.2a14.232,14.232,0,0,0,14.2,12.9,7.084,7.084,0,0,0,1.4-.1A14.391,14.391,0,0,0,88.4,492.5Z"
                                />
                                <path
                                    id="Path_1069"
                                    data-name="Path 1069"
                                    d="M104,571.9a14.253,14.253,0,1,0-27.3,8.2,439,439,0,0,0,33,80,14.371,14.371,0,0,0,12.6,7.6A13.363,13.363,0,0,0,129,666a14.251,14.251,0,0,0,5.9-19.3A419.55,419.55,0,0,1,104,571.9Z"
                                />
                                <path
                                    id="Path_1070"
                                    data-name="Path 1070"
                                    d="M370.6,29.2a446.848,446.848,0,0,0-79.9,33.4,14.2,14.2,0,0,0,6.8,26.7,14.487,14.487,0,0,0,6.7-1.7,407.854,407.854,0,0,1,74.7-31.2,14.393,14.393,0,0,0,9.5-17.8A14.175,14.175,0,0,0,370.6,29.2Z"
                                />
                                <path
                                    id="Path_1071"
                                    data-name="Path 1071"
                                    d="M129.2,238.1a14.224,14.224,0,0,0-19.3,5.9,435.548,435.548,0,0,0-33.1,79.9,14.312,14.312,0,0,0,9.5,17.8,15.07,15.07,0,0,0,4.1.6A14.1,14.1,0,0,0,104,332.2a416.516,416.516,0,0,1,30.9-74.8A14.1,14.1,0,0,0,129.2,238.1Z"
                                />
                                <path
                                    id="Path_1072"
                                    data-name="Path 1072"
                                    d="M219,110.7A447.3,447.3,0,0,0,158,172a14.284,14.284,0,0,0,2,20.1,13.9,13.9,0,0,0,9,3.3,14.273,14.273,0,0,0,11-5.2,412.3,412.3,0,0,1,57.1-57.3A14.323,14.323,0,0,0,219,110.7Z"
                                />
                            </g>
                        </g>
                    </g>
                </SvgIcon>
                <div
                    data-testid="release_cycle_text"
                    style={{
                        position: 'absolute',
                        transform: 'translateY(-10%)',
                    }}
                >
                    <StyledText
                        color={gradeColor ?? ''}
                        primaryText={value}
                        secondaryText={translate('global_sprints')}
                        primaryTextFontSize="2.5rem"
                    />
                </div>
            </Box>
        );
    }
}
