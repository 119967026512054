import { Sprint, projectApi, sprintApi } from '../../api/sentinel';
import metricMapper from './metricMapper';
import { DashboardData } from './dashboardData';
import metricService from './metricService';
import { SprintSprintStatusEnum } from '../../api/sentinel/openapi/models';

export function findLatestCompletedOrDraft(sprints: Sprint[] = []) {
    if (sprints.length === 0) return;

    const completedSprints = sprints.filter(
        s => s.sprintStatus === SprintSprintStatusEnum.Completed,
    );
    const draftSprints = sprints.filter(
        s => s.sprintStatus === SprintSprintStatusEnum.Draft,
    );

    const completedOrDraftSprints =
        completedSprints.length > 0 ? completedSprints : draftSprints;

    const latestCompletedOrDraft = completedOrDraftSprints.reduce(
        (latest, current) => {
            return latest.sprintEndDate > current.sprintEndDate
                ? latest
                : current;
        },
    );
    return latestCompletedOrDraft;
}

const dashboardService = {
    getById: async (projectId: string, selectedSprintId?: number) => {
        const [sprints = [], project, metricsData] = await Promise.all([
            sprintApi.findSprints({ projectId }),
            projectApi.getProject({ projectId }),
            metricService.getMetric(projectId, selectedSprintId),
        ]);

        const selectedOrLatestSprint =
            sprints.find(s => s.sprintId === selectedSprintId) ??
            findLatestCompletedOrDraft(sprints);

        return {
            sprints,
            project,
            selectedSprint: selectedOrLatestSprint,
            metricsData: metricMapper(metricsData),
        } as DashboardData;
    },
};

export default dashboardService;
