import { useTranslation } from 'react-i18next';
import WidgetContainer from '../common/widget/WidgetContainer';
import WidgetHeader from '../common/widget/WidgetHeader';
import WidgetItem from '../common/widget/WidgetItem';
import { EscapedDefects } from './charts/EscapedDefects';
import { CiActivities } from './charts/CiActivities';
import { UnitTestCoverage } from './charts/UnitTestCoverage';
import { Metric } from '../../api/sentinel';

const Quality = ({
    qualityMetric,
}: {
    qualityMetric?: Metric & {
        qualityTrend?: string;
        totalIssuesNew?: number;
        totalIssuesOpen?: number;
        escapedDefectsGrade?: string;
        unitTestCoverage?: number;
        unitTestCoverageGrade?: string;
        ciActivity?: string;
        ciActivityGrade?: string;
    };
}) => {
    const { t: translate } = useTranslation();
    return (
        <WidgetContainer
            gridTemplateColumns={'1fr 1fr'}
            gridTemplateAreas={'"H H"\n"A A"\n"B C"'}
        >
            <WidgetHeader
                gridArea={'H'}
                trend={qualityMetric?.qualityTrend}
                title={translate('quality_title')}
                subtitle={translate('quality_subtitle')}
            />
            <WidgetItem
                gridArea={'A'}
                title={translate('quality_escapedDefects_title')}
                subtitle={translate('quality_escapedDefects_subtitle')}
                name="escapedDefects"
            >
                <EscapedDefects
                    totalIssuesNew={qualityMetric?.totalIssuesNew}
                    totalIssuesOpen={qualityMetric?.totalIssuesOpen}
                    escapedDefectsGrade={qualityMetric?.escapedDefectsGrade}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'B'}
                title={translate('quality_unitTestCoverage_title')}
                subtitle={translate('quality_unitTestCoverage_subtitle')}
                name="unitTestCoverage"
            >
                <UnitTestCoverage
                    unitTestCoverage={qualityMetric?.unitTestCoverage}
                    unitTestCoverageGrade={qualityMetric?.unitTestCoverageGrade}
                />
            </WidgetItem>
            <WidgetItem
                gridArea={'C'}
                title={translate('quality_ci_activities_title')}
                subtitle={translate('quality_ci_activities_subtitle')}
                name="CIActivities"
            >
                <CiActivities
                    ciActivities={qualityMetric?.ciActivity}
                    ciActivitiesGrade={qualityMetric?.ciActivityGrade}
                ></CiActivities>
            </WidgetItem>
        </WidgetContainer>
    );
};
export default Quality;
