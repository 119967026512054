import GaugeChart from '../../common/charts/GaugeChart';

export const UnitTestCoverage = ({
    unitTestCoverage,
    unitTestCoverageGrade,
}: {
    unitTestCoverage?: number;
    unitTestCoverageGrade?: string;
}) => {
    return (
        <GaugeChart
            value={unitTestCoverage}
            grade={unitTestCoverageGrade}
            deltas={{
                f: 59.99,
                d: 10,
                c: 10,
                b: 10,
                a: 10.01,
            }}
            cy="60%"
            height={225}
            gaugeChartName="unitTestCoverage"
        />
    );
};
