import { Button, Popover, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import theme from '../../theme/theme';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const ConfirmationPanel = ({
    message,
    open,
    handleTransitionExited,
    onConfirm,
    onCancel,
    anchor,
}: {
    message: string;
    open: boolean;
    handleTransitionExited: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    anchor: HTMLElement;
}) => {
    const [disabled, setDisabled] = useState(false);

    const { t } = useTranslation();
    const translation = {
        title: t('global_confirm_delete'),
        deleteConfirmMessage: t('projectLinks_delete_confirm_message'),
        cancel: t('global_cancel'),
        yes: t('global_yes'),
    };

    const disableButtons = () => setDisabled(true);
    const enableButtons = () => setDisabled(false);

    return (
        <Popover
            open={open}
            anchorEl={anchor}
            marginThreshold={20}
            onClose={onCancel}
            onTransitionExited={() => {
                handleTransitionExited();
                enableButtons();
            }}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
        >
            <Stack
                sx={{
                    padding: '2em 2em 2em 2em',
                    alignContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Stack>
                    <Typography align={'left'} variant={'h2'}>
                        {translation.title}
                    </Typography>

                    <Typography padding={'1rem 0 1rem 0'}>
                        {translation.deleteConfirmMessage}:
                    </Typography>
                    <Typography
                        maxWidth={'20rem'}
                        sx={{ wordWrap: 'break-word' }}
                        align={'center'}
                        paddingBottom={'1.5rem'}
                    >
                        {message}
                    </Typography>
                </Stack>
                <Stack direction={'row'} spacing={3} justifyContent={'center'}>
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            onConfirm();
                            disableButtons();
                        }}
                        sx={theme.typography.button}
                        variant={'contained'}
                        data-testid={'confirm-delete-button'}
                    >
                        {translation.yes}
                    </Button>
                    <Button
                        disabled={disabled}
                        onClick={() => {
                            onCancel();
                            disableButtons();
                        }}
                        startIcon={<CloseIcon />}
                        data-testid={'cancel-delete-button'}
                    >
                        {translation.cancel}
                    </Button>
                </Stack>
            </Stack>
        </Popover>
    );
};

export default ConfirmationPanel;
