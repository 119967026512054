import React from 'react';
import Paper from '@mui/material/Paper';

const WidgetContainer = ({
    gridTemplateColumns,
    gridTemplateAreas,
    children,
}: {
    gridTemplateColumns: string;
    gridTemplateAreas: string;
    children: React.ReactNode;
}) => {
    return (
        <Paper
            variant="elevation"
            elevation={0}
            sx={{
                borderTopLeftRadius: '20px',
                display: 'grid',
                gridTemplateAreas: gridTemplateAreas,
                gridTemplateColumns: gridTemplateColumns,
                gridTemplateRows: '70px auto',
                height: '600px',
                overflow: 'hidden',
            }}
        >
            {children}
        </Paper>
    );
};

export default WidgetContainer;
