import { useQuery } from 'react-query';
import { type Location, locationApi } from '../api/sentinel';

const useLocations = () => {
    const { data, isLoading, isError, error } = useQuery<Location[], Error>(
        'locations',
        () => locationApi.findLocations(),
    );
    return { data, isLoading, isError, error };
};

export default useLocations;
