// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BurnupChart } from './BurnupChart';
import {
    BurnupChartFromJSON,
    BurnupChartFromJSONTyped,
    BurnupChartToJSON,
} from './BurnupChart';

/**
 *
 * @export
 * @interface BurnupChartResponse
 */
export interface BurnupChartResponse {
    /**
     *
     * @type {Array<BurnupChart>}
     * @memberof BurnupChartResponse
     */
    burnupChart: Array<BurnupChart>;
}

/**
 * Check if a given object implements the BurnupChartResponse interface.
 */
export function instanceOfBurnupChartResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && 'burnupChart' in value;

    return isInstance;
}

export function BurnupChartResponseFromJSON(json: any): BurnupChartResponse {
    return BurnupChartResponseFromJSONTyped(json, false);
}

export function BurnupChartResponseFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): BurnupChartResponse {
    if (json === undefined || json === null) {
        return json;
    }
    return {
        burnupChart: (json['burnupChart'] as Array<any>).map(
            BurnupChartFromJSON,
        ),
    };
}

export function BurnupChartResponseToJSON(
    value?: BurnupChartResponse | null,
): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        burnupChart: (value.burnupChart as Array<any>).map(BurnupChartToJSON),
    };
}
