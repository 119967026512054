export type TokenClaimsType = {
    iat: number;
    nbf: number;
    exp: number;
    name: string;
    preferred_username: string;
    roles: string[];
    family_name: string;
    given_name: string;
};

export type UserAuthInfoType = {
    name: string;
    preferred_username: string;
    roles: string[];
    expiration: Date;
    issuedAt: Date;
    notValidBefore: Date;
    familyName: string;
    givenName: string;
};

function claimsMapper(claims: TokenClaimsType): UserAuthInfoType {
    return {
        name: claims.name,
        preferred_username: claims.preferred_username,
        roles: claims.roles,
        expiration: new Date(claims.exp * 1000),
        issuedAt: new Date(claims.iat * 1000),
        notValidBefore: new Date(claims.nbf * 1000),
        familyName: claims.family_name,
        givenName: claims.given_name,
    };
}

function accessTokenParser(accessToken: string): UserAuthInfoType {
    const tokenPayload = accessToken.split('.')[1];
    try {
        return claimsMapper(JSON.parse(window.atob(tokenPayload)));
    } catch (_) {
        throw new Error(`Error while parsing token: ${tokenPayload}`);
    }
}

export default accessTokenParser;
