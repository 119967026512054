// @ts-nocheck
/* tslint:disable */
/* eslint-disable */
/**
 * Sentinel API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1-SNAPSHOT
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface Metric
 */
export interface Metric {}

/**
 * Check if a given object implements the Metric interface.
 */
export function instanceOfMetric(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MetricFromJSON(json: any): Metric {
    return MetricFromJSONTyped(json, false);
}

export function MetricFromJSONTyped(
    json: any,
    ignoreDiscriminator: boolean,
): Metric {
    return json;
}

export function MetricToJSON(value?: Metric | null): any {
    return value;
}
