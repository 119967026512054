import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from '@azure/msal-react';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import QueryProvider from './QueryProvider';
import router from './Router';
import useAuth from './authentication/useAuth';
import Spinner from './common/Spinner';
import ErrorBoundary from './common/errorBoundary/ErrorBoundary';
import './i18n/i18n';
import theme from './theme/theme';

interface AppProperties {
    usingMsal: boolean;
}

function WrappedComponents() {
    return (
        <ErrorBoundary>
            <QueryProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </QueryProvider>
        </ErrorBoundary>
    );
}

function App({ usingMsal }: AppProperties) {
    const { isReady } = useAuth(usingMsal);

    return (
        <ErrorBoundary>
            {usingMsal ? (
                <>
                    {isReady && (
                        <AuthenticatedTemplate>
                            <WrappedComponents />
                        </AuthenticatedTemplate>
                    )}
                    <UnauthenticatedTemplate>
                        <Spinner open={true}></Spinner>
                    </UnauthenticatedTemplate>
                </>
            ) : (
                <WrappedComponents />
            )}
        </ErrorBoundary>
    );
}

export default App;
