export function safeParseInt(valueString?: string) {
    if (valueString && valueString.trim()) {
        const numberValue = parseInt(valueString, 10);
        if (!isNaN(numberValue)) {
            return numberValue;
        }
        return undefined;
    }
    return undefined;
}

export function mapObject<T, U>(
    source: Record<string, T>,
    transform: (value: T) => U,
): Record<string, U> {
    return Object.fromEntries(
        Object.entries(source)
            .map(([key, value]) => [key, transform(value)])
            .filter(([, value]) => value !== undefined),
    );
}

export function intersect<T>(arr1: T[], arr2: T[]): T[] {
    return arr1.filter(value => arr2.includes(value));
}
